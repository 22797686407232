export function strings_de() {
    return {
        "PropertyPaneDescription": "Web Part Konfiguration",
        "BasicGroupName": "Konfiguration",
        "CommandBarEntries": "CommandBar Einträge",
        "DocumentUrlLabel": "Initiales Visio Document Url",
        "SiteLabel": "SharePoint Site",
        "DocumentLabel": "Visio Dokument",
        "SearchDrivenLabel": "Suche",
        "SearchDrivenQueryLabel": "URL",

        "BackendGroupName": "Backend",
        "FilterLabel": "ID der Datenbank",
        "TokenLabel": "Token",
        "ServiceLabel": "SemTalk Webservice Url",
        "ProcTable": "Prozesstabelle",
        "WebPartGroupName": "Web Part",
        "TitleLabel": "Titel",
        "DescriptionLabel": "Beschreibung",
        "WidthLabel": "Breite",
        "HeightLabel": "Höhe",
        "MinimizeLabel": "Minimieren",
        "HelpLabel": "Hilfe",
        "DescriptionFieldLabel": "Beschreibungsfeld",
        "UIGroupName": "Einstellungen",
        "SPListnameLabel": "SharePoint Liste",
        "SPURLLabel": "SharePoint URL",
        "NavigatorMyTasks": "Meine Aufgaben",

        "RootLabel": "Basisklasse",
        "PageSizeLabel": "Seitengröße",
        "ReportNameLabel": "Reportname",
        "IsFilterLabel": "Filtern",
        "IsReportsLabel": "Reportauswahl",

        "DetailsLabel": "Details",
        "GotoLabel": "Goto Nodes",
        "PropsLabel": "Eigenschaften",
        "ContextLabel": "Kontext",
        "PropsGroupedLabel": "Eigenschaften mit Gruppierung",
        "LinksLabel": "Teilen",
        "DiagramLabel": "Diagramm",
        "DocInfoLabel": "Visio Metadaten",
        "AttachmentLabel": "Hyperlinks",
        "DataObjectAttachmentLabel": "DataObject Hyperlinks",
        "TeamsLabel": "Teams",
        "BotLabel": "Chat Bot",
        "ListItemsLabel": "Liste",
        "WikiLabel": "Wiki",
        "WikiListLabel": "Wiki Titel",
        "WikiSiteLabel": "SharePoint URL",
        "BreadCrumbsLabel": "Brotkrumen",
        "CommandBarLabel": "Menü",
        "IsListLabel": "Liste statt Baum",
        "IsComboLabel": "Auswahl statt Baum",
        "GoodLabel": "Positivliste",
        "BadLabel": "Negativliste",
        "ObjRootLabel": "Startobjekt",
        "NavRootLabel": "Startseite",
        "GrpPropViews": "Sichten zur Gruppierung",
        "ListDocumentsLabel": "Dokumente",
        "RelationTree": "Relationsbaum",

        "TypeLabel": "Typ",
        "NodesLabel": "Visio-Shapes",
        "AssocLabel": "Assoziationen",
        "BPMNLabel": "BPMN",
        "SimLabel": "Simulation",
        "NavLabel": "Vor / Zurück",
        "NavPropLabel": "Navigieren",

        "TaskLabel": "Aufgabe",
        "RoleLabel": "Rolle",
        "RASCILabel": "RASCI",
        "CommentLabel": "Kommentar",
        "DOAttachmentLabel": "Link an Datenobjekt",
        "InputLabel": "Input",
        "OutputLabel": "Output",

        "NrOfDocumentsToShow": "Geben Sie die Anzahl der anzuzeigenden Dokumente an",
        "NoRecentDocuments": "Keine Dokumente gefunden.",
        "LastUsedMsg": "Zuletzt zugegriffen:",
        "Loading": "Laden",
        "Trending": "Rufe empfohlene Dokumente auf",
        "Error": "Ein Fehler ist aufgetreten.",

        "DateJustNow": "Soeben",
        "DateMinute": "1 Minute zuvor",
        "DateMinutesAgo": "Minuten zuvor",
        "DateHour": "1 Stunde zuvor",
        "DateHoursAgo": "Stunden zuvor",
        "DateDay": "Gestern",
        "DateDaysAgo": "Tage zuvor",
        "DateWeeksAgo": "Wochen zuvor",

        // CommandBar
        "Search": "Suche",
        "Close": "Schliessen",
        "Process": "Prozesstabelle",
        "Navigation": "Diagramme",
        "Hierarchy": "Objekte",
        "Reports": "Auswertungen",
        "Planner": "Planner",
        "RoleAssignment": "Rollenzuordnung",
        "Bot": "Chat Bot",
        "Issues": "Problemverfolgung",
        "Documents": "Hyperlinks",
        "UsedDocuments": "Verwendete Dokumente",
        "RecentDocuments": "Letzte Dokumente",
        "TrendingDocuments": "Empfohlene Dokumente",
        "People": "Experten",
        "Info": "Über SemTalk",
        "Diagram": "Diagramm",
        "List": "Listenanbindung",
        "Label": "Name",
        "Value": "Wert",
        "Image": "Bild",
        "Title": "Titel",
        "Home": "Start",
        "Add": "Neu anlegen",
        "Open": "Öffnen",
        "Edit": "Ändern",
        "Delete": "Löschen",
        "Submit": "Speichern",
        "Cancel": "Abbruch",
        "Objects": "Objekte",
        "Filter": "Filtern",
        "Column": "Spalte",
        "Object": "Objekt",
        "Roles": "Rollen",
        "Member": "Mitglied",
        "Task": "Aufgabe",
        "Completed": "Erledigt",
        "Bucket": "Bucket",
        "Channel": "Kanal",
        "ListItemDelete": "Wollen sie das markierte Element wirklich löschen?",
        "PlannerID": "Planner ID",
        "Portal": "Portal",
        "BotSecret": "Bot Secret",
        "Query": "Abfrage",
        "FilterShapeColumn": "Filter Shape Spalte",
        "FilterPageColumn": "Filter Seite Spalte",
        "FilterObjectColumn": "Filter Objekt Spalte",
        "FilterModelColumn": "Filter Modell Spalte",
        "GraphProps": "Graph benutzen",
        "GoToPage": "Gehe zur Seite",
        "GoToObject": "Gehe zum Objekt",
        "GoToNode": "Gehe zum Node",
        "GoToShape": "Gehe zum Shape",
        "LibraryLabel": "Bibliothek",
        "LibrarySiteLabel": "Bibliothek Seite",
        "SingleDocument": "Ein Dokument",
        "DirectEdit": 'Dokument in App editieren',
        "TeamsID": "Teams ID",
        "DefTopic": "Thema",
        "RefineLabel": "Interne Visio Hyperlinks",
        "RefineHyperlinkLabel": "Autom. Verfeinerung",
        "OpenExtHyperlinkLabel": "Ext. Hyperlinks öffnen",
        "dividetable": "Tabelle teilen"
    };
}