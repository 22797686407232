export let stconfig: any = {
    _service: "",
    _filter: "1",
    _token: "j3Ex0PaYX9FPVebgHPYWrEkL%2FMB7sJBYZKYFJMBPgqbhftmY6CloPDZlHFIFQtavujVacjD2T4LYZSOqtS3ZGLK9%2FEBq9mZonU4NDbVH5imOMfmf%2Fyv%2Bc08Wr1EQVQf%2BMI535%2Fu%2Fbj4Nwa%2Btolucwiljju58H9EDUds7fxW%2BCnfUr8jbArIUkCi8UV22fXPcjQQv%2BlgSbCdQVUNBjwgdWZF42Pn%2BTE5jxBr1YGrpdblhiVibR4msU6fDY90QDlGbk9yzxXNpZrL%2BVpSCyM63Qsn3GTv1QVQv%2Fu8KdSbcqfTFO1x1fqGAIfiSGIvJAQccnsyrUVouEZ3LvqGhubmiHk%2F8Su8KDl656ax59YAzbz8%3D",
    roleprocesses: []
};
//"http://localhost:50495/semservice/sembasemongo"
//  _service: "http://localhost:50595/semservice/sembaseservice",

  // Mongo and SemService 1, DB 0
  //  _filter: "SemTalkPortalDB45_1", // "Site"

