export enum SemTalkPortalCookie {
  pivot_showdetails = "pivot_showdetails",
  showprops = "showprops",
  pivot_showprops = "pivot_showprops",
  pivot_showdia = "pivot_showdia",
  props_gotonodes = "props_gotonodes",
  props_shownodes = "props_shownodes",
  props_showproperties = "props_showproperties",
  showpivot = "showpivot",
  showzoom = "showzoom",
  showsearch = "showsearch",
  shownav = "shownav",
  showhie = "showhie",
  showdia = "showdia",
  showpro = "showpro",
  showrepo = "showrepo",
  showlistitems = "showlistitems",
  proc_showtasks = "proc_showtasks",
  proc_showroles = "proc_showroles",
  proc_showrasci = "proc_showrasci",
  proc_showcomments = "proc_showcomments",
  proc_showattachment = "proc_showattachment",
  proc_showdoattachment = "proc_showdoattachment",
  proc_showinput = "proc_showinput",
  proc_showoutput = "",
  pivot_showlin = "pivot_showlin",
  pivot_showatt = "pivot_showatt",
  showcon = "showcon",
  showrep = "showrep",
  showdocinfo = "showdocinfo",
  useapi = "useapi",
  shownavigator = "shownavigator",
  svgprop = "svgprop",
  svghyperlink = "svghyperlink",
  svgrefine = "svgrefine",
  usedb45 = "usedb45",
  servicesdocument = "servicesdocument",
  servicesstartpage = "servicesstartpage",
  pivotvisible = "pivotvisible"
}