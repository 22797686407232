
export function strings_en() {
  return {    "PropertyPaneDescription": "Web part configuration",
    "BasicGroupName": "Configuration",
    "CommandBarEntries": "CommandBar Entries",
    "DocumentUrlLabel": "Initial Document Url - open document on web client and copy url from browser",
    "SiteLabel": "Site",
    "DocumentLabel": "Document",
    "BackendGroupName": "Backend",
    "ServiceLabel": "Service Url",
    "FilterLabel": "Site ID in the Database",
    "TokenLabel": "Token",

    "WebPartGroupName": "WebPart",
    "TitleLabel": "Title",
    "DescriptionLabel": "Description",
    "WidthLabel": "Width",
    "HeightLabel": "Height",
    "MinimizeLabel": "Minimize",
    "HelpLabel": "Help",
    "DescriptionFieldLabel": "Description Field",
    "UIGroupName": "Settings",
    "SPListnameLabel": "Listname",
    "SPURLLabel": "SharePoint URL",
    "SearchDrivenLabel": "Search",
    "SearchDrivenQueryLabel": "URL",
    "NavigatorMyTasks": "My Tasks",

    "RootLabel": "Root Class Name",
    "PageSizeLabel": "PageSize",
    "ReportNameLabel": "ReportName",
    "IsFilterLabel": "IsFilter",
    "IsReportsLabel": "IsReports",

    "DetailsLabel": "Details",
    "GotoLabel": "Goto Nodes",
    "PropsLabel": "Properties",
    "ContextLabel": "Context",
    "PropsGroupedLabel": "Properties with Grouping",
    "LinksLabel": "Share",
    "DiagramLabel": "Diagram",
    "DocInfoLabel": "Visio Meta Data",
    "AttachmentLabel": "Attachments",
    "DataObjectAttachmentLabel": "DataObject Attachments",
    "TeamsLabel": "Teams",
    "BotLabel": "Chat Bot",
    "ListItemsLabel": "ListItems",
    "WikiLabel": "Wiki",
    "WikiListLabel": "Wiki Title",
    "WikiSiteLabel": "SharePoint URL",
    "BreadCrumbsLabel": "BreadCrumbs",
    "CommandBarLabel": "CommandBar",
    "IsListLabel": "IsList",
    "IsComboLabel": "IsCombo",
    "GoodLabel": "Goodlist",
    "BadLabel": "Badlist",
    "ObjRootLabel": "Hierarchy Root",
    "NavRootLabel": "Navigation Root",
    "GrpPropViews": "Views for Grouping",
    "ListDocumentsLabel": "ListDocuments",
    "RelationTree": "Relation Tree",

    "TypeLabel": "Type",
    "NodesLabel": "Nodes",
    "AssocLabel": "Associations",
    "BPMNLabel": "BPMN",
    "SimLabel": "Simulation",
    "NavLabel": "Nav Buttons",
    "NavPropLabel": "Navigate",

    "TaskLabel": "Task",
    "RoleLabel": "Role",
    "RASCILabel": "RASCI",
    "CommentLabel": "Comment",
    "DOAttachmentLabel": "DOAttachment",
    "InputLabel": "Input",
    "OutputLabel": "Output",

    "NrOfDocumentsToShow": "Specify the number of items to show",
    "NoRecentDocuments": "We did not find anything.",
    "LastUsedMsg": "Last accessed:",
    "Loading": "Retrieving...",
    "Trending": "Retrieving...",
    "Error": "Sorry, something failed.",

    "DateJustNow": "just now",
    "DateMinute": "1 minute ago",
    "DateMinutesAgo": "minutes ago",
    "DateHour": "1 hour ago",
    "DateHoursAgo": "hours ago",
    "DateDay": "yesterday",
    "DateDaysAgo": "days ago",
    "DateWeeksAgo": "weeks ago",

    // CommandBar
    "Search": "Search",
    "Close": "Close",
    "Process": "Process",
    "ProcTable": "Process table",
    "Navigation": "Navigation",
    "Hierarchy": "Hierarchy",
    "Reports": "Reports",
    "Planner": "Planner",
    "RoleAssignment": "Role Assignment",
    "Bot": "Chat Bot",
    "Issues": "Issues",
    "Documents": "Documents",
    "UsedDocuments": "Used Documents",
    "RecentDocuments": "Recent Documents",
    "TrendingDocuments": "Trending Documents",
    "People": "People",
    "Info": "Info",
    "List": "List",
    "Diagram": "Diagram",
    "Label": "Name",
    "Image": "Bild",
    "Title": "Title",
    "Home": "Home",
    "Value": "Value",
    "Add": "Add New",
    "Open": "Open",
    "Edit": "Edit",
    "Delete": "Delete",
    "Submit": "Submit",
    "Cancel": "Cancel",
    "Objects": "Objects",
    "Filter": "Filter",
    "Column": "Column",
    "Object": "Object",
    "Roles": "Roles",
    "Member": "Member",
    "Task": "Task",
    "Completed": "Completed",
    "Bucket": "Bucket",
    "Channel": "Channel",
    "ListItemDelete": "Do you really want to delete the selected element?",
    "PlannerID": "Planner ID",
    "Portal": "Portal",
    "BotSecret": "Bot Secret",
    "Query": "Query",
    "FilterShapeColumn": "Filter Shape Column",
    "FilterPageColumn": "Filter Page Column",
    "FilterObjectColumn": "Filter Object Column",
    "FilterModelColumn": "Filter Model Column",
    "GraphProps": "Use Graph",
    "GoToPage": "Go to Page",
    "GoToObject": "Go to Object",
    "GoToNode": "Go to Node",
    "GoToShape": "Go to Shape",
    "LibraryLabel": "Library",
    "LibrarySiteLabel": "Library Site",
    "SingleDocument": "Single Document",
    "DirectEdit": 'Enable direct Editing',
    "TeamsID": "Teams ID",
    "DefTopic": "Default Topic",
    "RefineLabel": "Internal Visio Hyperlinks",
    "RefineHyperlinkLabel": "Autom. Refinement",
    "OpenExtHyperlinkLabel": "Open ext. Hyperlinks",
    "dividetable": "Divide Table"
  }
}