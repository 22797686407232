import * as React from 'react';
// import styles from './SemTalkReport.module.scss';
import {
  Link, IColumn, DetailsList, DetailsListLayoutMode, CheckboxVisibility,
  Dropdown, IDropdownOption, DefaultButton, Icon,
  // Toggle
} from 'office-ui-fabric-react';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { SelectionMode } from 'office-ui-fabric-react/lib/utilities/selection/index';

import { Reports, ReportRowCells, IReportCellTable, ReportCellsInterval, FindObjectByID, NodeNames } from '../../application/dbase';
// import { WebPartContext } from '@microsoft/sp-webpart-base';
// import { SetContext } from '../../application/restinterface';
import { gotoObject, gotoDocument, addCallBack, removeCallBack, getDiagram, gotoNode } from '../../application/stglobal';
import { SemTalkReportFilter } from '../streportfilter/SemTalkReportFilter';
import { Guid } from "guid-typescript";
import { ISemService } from '../../../options';
// import * as strings from 'SemTalkStrings';

export interface ISemTalkReportProps {
  semservice: ISemService;
  isfilter?: boolean;
  isreports?: boolean;
  reportname?: string;
  pagesize: number;
  strings: ISemTalkStrings;
}
export interface IFilter {
  colid: number;
  column: string;
  //  operator: string;
  value: string;
  objectid: number;

}

export interface ISemTalkReportState {
  reports: IDropdownOption[];
  columns: IColumn[];
  filters: IFilter[];
  results: any[];
  isfilter: boolean;
  reportid: number;
  reportfilter: string;

}

export default class SemTalkReport extends React.Component<ISemTalkReportProps, ISemTalkReportState> {
  private pagesize: number = 5;
  private minrow: number = 1;
  private maxrow: number = this.pagesize + 1;
  public callback: Guid;

  constructor(props: ISemTalkReportProps) {
    super(props);
    console.log("SemTalkReport");
    let pgsize: string = this.props.pagesize.toString();
    this.pagesize = parseInt(pgsize);
    this.maxrow = this.minrow + this.pagesize;
    this.callback = Guid.create();
    this.state = {
      reports: [],
      columns: [],
      filters: [],
      results: [],
      isfilter: (this.props.isfilter === undefined ? false : this.props.isfilter),
      reportid: -1,
      reportfilter: ""
    };
  }


  // private reports: IDropdownOption[] = [];

  private loadReports = async () => {
    let _reports: IDropdownOption[] = [];
    const rl = await Reports();
    for (let r of rl) {
      let rs: number = r.ReportID;
      let rname = r.ObjectName;
      if (rname.indexOf("/") >= 0) {
        rname = rname.substr(rname.lastIndexOf("/") + 1);
      }
      rname = rname.replace(".xml", "");
      _reports.push({ key: rs, text: rname });
    }
    this.setState({ reports: _reports });
  }

  private _onReportChange = async (option: IDropdownOption, _index?: number, _value?: string) => {
    if (option) {
      let rid = option.key as number;
      // console.debug("ReportChanged: ", option);
      await this.RunReport(rid, this.minrow, this.maxrow, this.state.reportfilter);
      this.setState({ reportid: rid });
    }
  }
  private RunReport = async (reportid: number, minrow: number, maxrow: number, filter: string) => {
    // this.setState({
    //   columns: [],
    //    results: [],
    // });
    if (reportid < 0) return;

    if (filter === undefined) {
      filter = "";
    }
    const _columns: IColumn[] = [];
    const cols = await ReportRowCells(reportid, 0);
    for (let cell of cols) {
      _columns.push(
        {
          key: cell.ColumnID.toString(),
          name: cell.Value,
          fieldName: cell.ColumnID.toString(),
          minWidth: 100,
          isResizable: true,
          isMultiline: true,
          onRender: (item: any, _index: number | undefined, column?: IColumn) => {
            if (column) {
              let vcell = item.Cells[column.key];
              if (vcell.ObjectID > 0) {
                let cap = vcell.ValueCaption;
                return <Link key={item} onClick={async () => {
                  await _clickany(vcell);
                }}>
                  {cap}
                </Link>;
              } else {
                let cap = vcell.Value;
                if (vcell.ValueCaption.length > 0) {
                  cap = vcell.ValueCaption;
                }
                if (cap.indexOf("://") > 0) {
                  return <Link href={vcell.Value} target="_blank">{cap}</Link>;
                } else {
                  return <span>{cap}</span>;
                }
              }
            } else {
              return <span />;
            }
          }
        });
    }
    let _items: IReportCellTable[] = [];
    let newrow: any = null;
    let rowid: number = -1;
    let colcnt: number = 0;
    const colsint = await ReportCellsInterval(reportid, minrow, maxrow, _columns.length, filter);
    for (let cell of colsint) {
      if (cell.RowID !== rowid) {
        colcnt = 0;
        rowid = cell.RowID;
        if (newrow !== null && newrow.RowID !== 0) {
          _items.push(newrow);
        }
        newrow = {};
        newrow.RowID = rowid;
        newrow.Cells = [];
      } else {
        colcnt = colcnt + 1;
      }
      newrow.Cells.push(cell);
    }
    if (newrow !== null) {
      _items.push(newrow);
    }
    this.setState({
      columns: _columns,
      results: _items,
    });
  }

  private _clickForward = async () => {
    // if (this.minrow === 1) {
    //   this.minrow = this.minrow - 1;
    //   this.maxrow = this.maxrow - 1;
    // }
    this.minrow = this.minrow + this.pagesize;
    this.maxrow = this.maxrow + this.pagesize;
    await this.RunReport(this.state.reportid, this.minrow, this.maxrow, this.state.reportfilter);
  }
  private _clickFirst = async () => {
    this.minrow = 1;
    this.maxrow = this.pagesize + 1;
    await this.RunReport(this.state.reportid, this.minrow, this.maxrow, this.state.reportfilter);
  }
  private _clickBackward = async () => {
    if (this.minrow <= this.pagesize) {
      await this._clickFirst();
    } else {
      this.minrow = this.minrow - this.pagesize;
      this.maxrow = this.maxrow - this.pagesize;
      await this.RunReport(this.state.reportid, this.minrow, this.maxrow, this.state.reportfilter);
    }
  }

  public render(): React.ReactElement<ISemTalkReportProps> {
    let pgsize: string = this.props.pagesize.toString();
    if (this.pagesize === parseInt(pgsize)) {
      this.pagesize = parseInt(pgsize);
      this.maxrow = this.minrow + this.pagesize;
    }
    return (
      <Fabric>
        {/* <div className={styles.semTalkReport} >
          <div className={styles.container}>
            {this.props.title && <div className={styles.row}>
              <div className={styles.column}>
                <div className={styles.title}>{this.props.title}</div>
              </div>
            </div>
            }
          </div>
        </div> */}
        {/* <div className={styles.row}>
               <div className={styles.column}> */}
        <div>
          <div>
            <div>
              <Dropdown
                // defaultSelectedKey="C"
                options={this.state.reports}
                // componentRef={this._basicComboBox}
                onChanged={this._onReportChange}
              />
            </div>
            {/* <div className={styles.column}>
              <Toggle label={this.props.strings.Filter} onChanged={this._onFilterToogleChange} checked={this.state.isfilter}></Toggle>
            </div> */}
          </div>
        </div>
        {/* </div> This should be better: https://www.npmjs.com/package/office-ui-fabric-react-pagination
              <Pagination
            currentPage={1}
            totalPages={10}
            onChange={(page) => {alert('Send user to page: ' + page)}}
        />
        */}
        {!this.state.isfilter &&
          <div>
            <DefaultButton onClick={this._clickFirst}><Icon iconName="Rewind" /></DefaultButton>
            <DefaultButton onClick={this._clickBackward}><Icon iconName="Previous" /></DefaultButton>
            <DefaultButton onClick={this._clickForward}><Icon iconName="Next" /></DefaultButton>
          </div>
        }
        {this.state.isfilter &&
          <SemTalkReportFilter reportid={this.state.reportid}
            semservice={this.props.semservice}
            strings={this.props.strings}
            filters={this.state.filters} applyfilter={this.applyfilter} />
        }
        {!this.state.isfilter &&
          <DetailsList
            // ref={this._resolveList}
            items={this.state.results}
            compact={true}
            columns={this.state.columns}
            selectionMode={SelectionMode.single}
            // setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            // selection={this._selection}
            checkboxVisibility={CheckboxVisibility.hidden}
          />
        }
      </Fabric>
    );
  }

  // private _onFilterToogleChange = (checked: boolean) => {
  //   this.setState({ isfilter: checked });
  // }
  private applyfilter = async (filter: string, filters: IFilter[]) => {
    await this.RunReport(this.state.reportid, this.minrow, this.maxrow, filter);
    this.setState({
      reportfilter: filter,
      filters: filters,
      isfilter: false
    });
  }

  private mounted: boolean = false;
  public async componentDidMount() {
    // SetContext(this.props.semservice);
    await this.loadReports();
    addCallBack(this);
    this.mounted = true;
  }
  public componentWillUnmount() {
    removeCallBack(this);
    this.mounted = false;
  }
  public handleEvent = async (m: any): Promise<void> => {
    let mstr = JSON.stringify(m);
    this.eventListener({ data: mstr });
  }

  private eventListener = (e: any): void => {
    if (!this.mounted) {
      return;
    }
    let md: any;
    let mtype: string = "";
    try {
      md = JSON.parse(e.data);
      mtype = md.type;
    } catch (e) {
      return;
    }
    switch (mtype) {
      case "gotoDocument":
        break;
      case "gotoObject":
        break;
      case "gotoNode":
        break;
    }
  }
}
async function _clickany(p: any) {
  let id = p.ObjectID;
  let obj = await FindObjectByID(id);
  if (obj) {
    switch (obj.ObjectType) {
      case "Diagram":
        await gotoDocument(id);
        break;
      default: {
        let thisdiag: number = getDiagram();
        let nn = await NodeNames(id);
        for (let n of nn) {
          if (n.NodePage === thisdiag) {
            await gotoNode(n.ID, n.Object, n.NodePage, n.NodeShape);
            return;
          }
        }
      }
    }
    await gotoObject(p.ObjectID);
  }
}
