export enum SemTalkCookie {
  contextpage = "contextpage",
  contextshp = "contextshp",
  contextobj = "contextobj",
  contextmodel = "contextmodel",
  guilanguage = "guilanguage",
  language = "language",
  simulation = "simulation",
  bpmn = "bpmn",
  refinements = "refinements",
  showlanguage = "showlanguage",
  showtoolbar = "showtoolbar",
  showmenubar = "showmenubar",
  showbreadcrumbs = "showbreadcrumbs",
  dockpanandzoom = "dockpanandzoom",
  docknavigator = "docknavigator",
  usedialogs = "usedialogs",
  shapesleftside = "shapesleftside",
  showquickshapes = "showquickshapes",
  allownegativecoordinates = "allownegativecoordinates",
  showhyperlinkmarker = "showhyperlinkmarker",
  semmongoserverurl = "semmongoserverurl",
  usemongo = "usemongo",
  usegraph = "usegraph",
  semconnecttoken = "semconnecttoken",
  dbname = "dbname",
  repository = "repository",
  SemTalkStatus = "SemTalkStatus",
  autosaveSemTalk = "autosaveSemTalk",
  autoComplete = "autoComplete",
  shapestyle = "shapestyle",
  guifont = "guifont",
  font = "font",
  fontsize = "fontsize",
  semanticsserver = "semanticsserver",
  semanticscorpus = "semanticcorpus",
  semanticsdistance = "semanticsdistance",
  applicationid = "applicationid",
  authority = "authority",
  scopes = "scopes",
  termsetgroup = "termsetgroup",
  termset = "termset",
  termsetroot = "termsetroot",
  termsetassoc = "termsetassoc",
  teamid = "teamid",
  channelid = "channelid",
  planid = "planid",
  theme = "theme",
  backgroundColor = "backgroundColor",
  userDefTheme = "userDefTheme",
  themeStyle = "themeStyle",
  iconcolor = "iconcolor",
  role = "role",
  autorefresh = "autorefresh",
  history = "history",
  historypos = "historypos",
  panzoom = "panzoom",
  pages = "pages",
  navigator = "navigator",
  stencil = "stencil",
  width = "width",
  height = "height",
  shapescale = "shapescale",
  resizeall = "resizeall",
  quickedit = "quickedit",
  sharepointrepository = "sharepointrepository",
  servicesconstr = "servicesconstr",
  servicessite = "servicessite",
  servicesserver = "servicesserver",
  servicesservertype = "servicesservertype"
}
