import {
  allModels,
  allObjects, allReports, allRootClasses, allRootDiagrams, allSites, breadcrumbs, diagramattachments, diagramsvg, findDiagram, findModel, findNode, findObject, getModel, getNode,
  getObject, getSite, instances, invlinkedObjects, invlinks, linkedObjects, links, metamodel, nodeNames, proctable, refinements,
  // reportCells, 
  reportCellsPaged, reportRowCells, reportRows, search, searchpage, subclasses, superclasses
} from './gettable';
import * as restinterface from './restinterface';

let useapi: boolean = false;

export function SetApi(u: boolean) {
  useapi = u;
}

// import * as tables from './Table';
export interface LogTable {
  SiteID: number;
  TimeStamp: string;
  Server: string;
  Action: string;
  Info: string;
  User: string;
  Referer: string;
}
export interface IModelTable {
  ID: number;
  ModelName: string;
  ObjectName: string;
  Site: number | undefined;
  UniqueID: string | undefined;
  // constructor(ID: number) {
  //   this.ID = ID;
  // }
}
export interface ISiteTable {
  ID: number;
  SiteName: string;
  // constructor(ID: number) {
  //   this.ID = ID;
  // }
}
export interface ILanguageTable {
  ID: number;
  ObjectName: string;
  // constructor(ID: number) {
  //   this.ID = ID;
  // }
}
export interface IContextTable {
  ID: number;
  ObjectCaption: string;
  Weight: number;
  Focus: number;
  // constructor(ID: number) {
  //   this.ID = ID;
  // }

}
// export class MetaModelTable {
//     public ID: number;
//     public Variable: string;
// }
export interface IActivityTable {
  ProcessID: number;
  ProcessCaption: string;
  ActivityID: number;
  ActivityCaption: string;
  RoleID: number | undefined;
  RoleCaption: string | undefined;
  RASCI: string | undefined;
  Comment: string | undefined;
  Attachment: string | undefined;
  DataObjectAttachment: string | undefined;
  InputID: number | undefined;
  InputCaption: string | undefined;
  OutputID: number | undefined;
  OutputCaption: string | undefined;
}
export interface IAttributeTable {
  ID: number;
  AttributeTypeID: number;
  ObjectCaption: string;
  AttributeValue: string | undefined;
  // constructor(ID: number) {
  //   this.ID = ID;
  // }
}
export interface IModelAttributeTable {
  // public ID: number;
  // public AttributeTypeID: number;
  AttributeTypeName: string;
  AttributeValue: string;
}
export interface IAssociationTable {
  ID: number;
  ObjectClass: number;
  FromObjectID: number;
  ToObjectID: number;
  AttributeValue: string | undefined;
  ObjectCaption: string | undefined;
  FromObjectCaption: string | undefined;
  ToObjectCaption: string | undefined;
  // constructor(ID: number) {
  //   this.ID = ID;
  // }
}
export interface ISynonymTable {
  Language: number;
  ObjectID: number;
  ObjectCaption: string;
}
export interface IPropertyTable {
  ID: number;
  ObjectID: number;
  PropName: string;
  PropType: string;
  PropValue: number;
  PropValueName: string;
  PropValueCaption: string;
  PropValueModel: number;
  // constructor(ID: number, ObjectID: number, PropValueName: string) {
  //   this.ID = ID;
  //   this.ObjectID = ObjectID;
  //   this.PropValueName = PropValueName;
  // }
}
export interface INodeTable {
  ID: number;
  NodePage: number;
  Object: number;
  ObjectID: number;
  NodeShape: string;
  DiagramName: string;
  ObjectCaption: string;
  NodeModel: number;
  SysObject: number;
  //   constructor(ID: number, ObjectID: number, NodePage: number, NodeShape: string) {
  //     this.ID = ID;
  //     this.ObjectID = ObjectID;
  //     this.NodePage = NodePage;
  //     this.NodeShape = NodeShape;
  //  }
}
export interface ICompositionTable {
  ClassID: number;
  ClassCaption: string;
  MethodID: number;
  MethodCaption: string;
  StateID: number;
  StateCaption: string;
  AttributeID: number;
  AttributeCaption: string;
  OtherID: number;
  OtherCaption: string;
  OperatorIsNot: string;
  OperatorValue: string;
  OperatorOperator: string;
}
export interface IDefinitionTable {
  ID: number;
  Language: number;
  Comment: string;
  // constructor(ID: number) {
  //   this.ID = ID;
  // }
}
export interface IObjectTable {
  ID: number;
  ObjectCaption: string;
  ObjectName: string;
  ObjectNamespace: string;
  Comment: string | undefined;
  ObjectClass: number | undefined;
  IsSystem: boolean;
  IsReadOnly: boolean;
  ObjectSystemClass: number | undefined;
  ObjectModel: number;
  ObjectType: string;
  isleaf: boolean;
  // constructor(ID: number, ObjectName: string, ObjectModel: number) {
  //   this.ID = ID;
  //   this.ObjectName = ObjectName;
  //   this.ObjectModel = ObjectModel;
  // }

  // Refinements(): DiagramTable[] { return dbase.Refinements(this.ID); }
  // RefinementsAsync(succeed: (x: DiagramTable) => any, nextfunction?: () => void) {restinterface.MapTableAsyncIDUrl(this.ID, "Refinements", succeed, nextfunction);}
  // public async Attributes(): Promise<IAttributeTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "Attributes"); }
  // public async Attachments(): Promise<InstanceTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "Attachments"); }
  // public async Definition(langid: string): Promise<DefinitionTable> { return restinterface.GetTableByIDLangUrl(this.ID, langid, "Definition"); }
  // // public SystemClass(): ClassTable { return dbase.FindClassByID(this.ObjectSystemClass); }
  // // public Model(): ModelTable { return dbase.FindModelByID(this.ObjectModel); }
  // public async Links(): Promise<IPropertyTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "Links"); }
  // public async InvLinks(): Promise<IPropertyTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "InvLinks"); }
  // public async Synonyms(): Promise<ISynonymTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "Synonyms"); }
  // public async NodeNames(): Promise<INodeTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "NodeNames"); }
  // public async Composition(): Promise<CompositionTable> { return restinterface.GetTableAsyncByIDUrl(this.ID, "Composition"); }
  // public async AssociationTypes(): Promise<ClassTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "AssociationTypes"); }
  // public async InvAssociationTypes(): Promise<ClassTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "InvAssociationTypes"); }
  // public async LinkedObjects(relid: number): Promise<ObjectTable[]> { return restinterface.GetTableAsyncIDRelIDUrl(this.ID, relid, "LinkedObjects"); }
  // public async InvLinkedObjects(relid: number): Promise<ObjectTable[]> { return restinterface.GetTableAsyncIDRelIDUrl(this.ID, relid, "InvLinkedObjects"); }

  // public async Associations(relid: number): Promise<IAssociationTable[]> { return restinterface.GetTableAsyncIDRelIDUrl(this.ID, relid, "Associations"); }
  // public async InvAssociations(relid: number): Promise<IAssociationTable[]> { return restinterface.GetTableAsyncIDRelIDUrl(this.ID, relid, "InvAssociations"); }
}
export interface IClassTable extends IObjectTable {
  // ObjectType = "Class";
  // public async SubClasses(): Promise<ClassTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "SubClasses"); }
  // public async AllSubClasses(): Promise<ClassTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "AllSubClasses"); }
  // public async SuperClasses(): Promise<ClassTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "SuperClasses"); }
  // public async AllSuperClasses(): Promise<ClassTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "AllSuperClasses"); }
  // public async Instances(): Promise<InstanceTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "Instances"); }
  // public async Methods(): Promise<ObjectTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "Methods"); }
  // public async States(): Promise<ObjectTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "States"); }
  // public async DiagramInstances(): Promise<DiagramTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "DiagramInstances"); }
}
export interface IInstanceTable extends IObjectTable {
  // ObjectType = "Instance";
  // public async InstanceOf(): Promise<ClassTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "InstanceOf"); }
  // public async RoleProcesses(): Promise<DiagramTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "RoleProcesses"); }
}
export interface IDiagramTable extends IInstanceTable {
  // ObjectType = "Diagram";
  isleaf: boolean;
  // public async Contents(): Promise<INodeTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "Contents"); }
  // public async ProcessRoles(): Promise<InstanceTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "ProcessRoles"); }
  // public async Refinements(): Promise<DiagramTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "Refinements"); }
  // public async InvRefinements(): Promise<DiagramTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "InvRefinements"); }
  // public async BreadCrumbs(): Promise<DiagramTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "BreadCrumbs"); }
  // public async ProcTable(): Promise<IActivityTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "ProcTable"); }
  // public async DiagramAttachments(): Promise<InstanceTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ID, "DiagramAttachments"); }
}
export interface IRefinementTable {
  ObjectID: number;
  ID: number;
  ObjectCaption: string;
}
export interface IInheritanceTable {
  ObjectID: number;
  ID: number;
  ObjectCaption: string;
  Name: string;
  isleaf: boolean;
}

export interface IDiagramAttachment {
  Name: string;
  ID: number;
  ObjectID: number;
  ObjectName: string;
}
export interface IVariableTable {
  Varaible: string;
  Class: number;
}
export interface IReportTable {
  ReportID: number;
  SiteName: string | undefined;
  ObjectName: string;
  URL: string | undefined;
  Specification: string | undefined;
  // public async Rows(): Promise<ReportRowTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ReportID, "ReportRows"); }
  // public async Cells(): Promise<ReportCellTable[]> { return restinterface.GetTableAsyncByIDUrl(this.ReportID, "ReportCells"); }
  //   constructor(ID: number) {
  //     this.ReportID = ID;
  //  }
}
export interface IReportRowTable {
  ReportID: number;
  RowID: number;
  // public async Cells(): Promise<ReportCellTable[]> { return restinterface.GetTableAsyncIDUrlrowid(this.ReportID, this.RowID, "ReportRowCells"); }
  // constructor(ReportID: number, RowID: number) {
  //   this.ReportID = ReportID;
  //   this.RowID = RowID;
  // }
}
export interface IReportCellTable {
  ReportID: number;
  RowID: number;
  ColumnID: number;
  ObjectID: number;
  Value: string;
  ValueCaption: string;
  // constructor(ReportID: number, RowID: number, ColumnID: number) {
  //   this.ReportID = ReportID;
  //    this.RowID = RowID;
  //    this.ColumnID = ColumnID;
  //   }
}
export interface IFolderElementTable {
  FolderElementID: number;
  FolderCode: string | undefined;
  SiteID: number | undefined;
  FolderID: number | undefined;
  ObjectID: number | undefined;
  ObjectSystemClass: number | undefined;
  ObjectCaption: string | undefined;
  isfolder: boolean;
  isleaf: boolean;

}
export interface IExecutionTable {
  SiteID: number | undefined;
  pid: number;
  ObjectCaption: string | undefined;
  entry: number | undefined;
  prio: number | undefined;
  starttime: string | undefined;
  endtime: string | undefined;
  working: number | undefined;
  // async Jobs(): Promise<JobTable[]> { return restinterface.GetTableAsyncByIDUrl(this.pid, "ExecutionJobs"); }
  // public async Objects(): Promise<ObjectTable[]> { return restinterface.GetTableAsyncByIDUrl(this.pid, "ExecutionObjects"); }
  // public async ResourceEvents(): Promise<ResourceEvent[]> { return restinterface.GetTableAsyncByIDUrl(this.pid, "ResourceEvents"); }
  // public async SystemEvents(): Promise<SystemEvent[]> { return restinterface.GetTableAsyncByIDUrl(this.pid, "SystemEvents"); }
  // public async SyncEvents(): Promise<SyncEvent[]> { return restinterface.GetTableAsyncByIDUrl(this.pid, "SyncEvents"); }
  // constructor(pid: number) {
  //   this.pid = pid;
  // }
}
export interface IJobTable {
  SiteID: number | undefined;
  JobID: number;
  ObjectCaption: string | undefined;
  ExecutionID: number | undefined;
  type: string | undefined;
  ActivityID: number | undefined;
  prio: number | undefined;
  starttime: string | undefined;
  endtime: string | undefined;
  Resumes: number | undefined;
  Breaks: number | undefined;
  // public async Triggers(): Promise<JobTable[]> { return restinterface.GetTableAsyncByIDUrl(this.JobID, "JobTriggers"); }
  // public async Objects(): Promise<ObjectTable[]> { return restinterface.GetTableAsyncByIDUrl(this.JobID, "JobObjects"); }
  // public async Resources(): Promise<ResourceEvent[]> { return restinterface.GetTableAsyncByIDUrl(this.JobID, "JobResources"); }
  // public async Systems(): Promise<SystemEvent[]> { return restinterface.GetTableAsyncByIDUrl(this.JobID, "JobSystems"); }
  // public async Syncs(): Promise<SyncEvent[]> { return restinterface.GetTableAsyncByIDUrl(this.JobID, "JobSyncs"); }
  // constructor(JobID: number) {
  //   this.JobID = JobID;
  // }
}
export interface IResourceEvent {
  type: string | undefined;
  rid: number | undefined;
  pid: number | undefined;
  jid: number | undefined;
  act: number | undefined;
  starttime: string | undefined;
  endtime: string | undefined;
  rest: string | undefined;
  cancelled: boolean | undefined;
  amount: number | undefined;
}
export interface ISystemEvent {
  type: string | undefined;
  buf: number | undefined;
  pid: number | undefined;
  jid: number | undefined;
  act: number | undefined;
  starttime: string | undefined;
  endtime: string | undefined;
  inf: number | undefined;
}
export interface ISyncEvent {
  pid: number | undefined;
  jid: number | undefined;
  act: number | undefined;
  starttime: string | undefined;
  endtime: string | undefined;
  count: number | undefined;
}
// export class DynObjectTable extends ObjectTable {
//   ExecutionID: number;
// }



export async function Log(_msg: string) {
  // return xml2json.ParseLogTable(restinterface.GetTableMsg("Log", msg));
}

export async function AllSites(): Promise<ISiteTable[]> {
  return useapi ? allSites() : restinterface.GetTableL("AllSites");
}
export async function AllLanguages(): Promise<ILanguageTable[]> { return restinterface.GetTableUrl("AllLanguages"); }
export async function AllSynonyms(): Promise<ISynonymTable[]> { return restinterface.GetTableUrl("AllSynonyms"); }
export async function AllModels(): Promise<IModelTable[]> {
  return useapi ? allModels() : restinterface.GetTableUrl("AllModels");
}
export async function AllAssociations(): Promise<IAssociationTable[]> { return restinterface.GetTableUrl("AllAssociations"); }
export async function AllAssociationTypes(): Promise<IClassTable[]> { return restinterface.GetTableUrl("AllAssociationTypes"); }
export async function AllClasses(): Promise<IClassTable[]> {
  return useapi ? allObjects("Class") : restinterface.GetTableUrl("AllClasses");
}
export async function AllDiagrams(): Promise<IDiagramTable[]> {
  return useapi ? allObjects("Diagram") : restinterface.GetTableUrl("AllDiagrams");
}
export async function AllDiagramTypes(): Promise<IClassTable[]> {
  return useapi ? allObjects("DiagramType") : restinterface.GetTableUrl("AllDiagramTypes");
}
export async function AllInstances(): Promise<IInstanceTable[]> {
  return useapi ? allObjects("Instance") : restinterface.GetTableUrl("AllInstances");
}
export async function AllMethodTypes(): Promise<IClassTable[]> {
  return restinterface.GetTableUrl("AllMethodTypes");
}
export async function AllRootClasses(): Promise<IClassTable[]> {
  return useapi ? allRootClasses() : restinterface.GetTableUrl("AllRootClasses");
}
export async function AllRootDiagrams(id: number): Promise<IDiagramTable[]> {
  return useapi ? allRootDiagrams(id) : restinterface.GetTableByIDUrl(id, "AllRootDiagrams");
}
export async function AllSystemClasses(): Promise<IClassTable[]> { return restinterface.GetTableUrl("AllSystemClasses"); }
export async function AllAttachments(): Promise<IInstanceTable[]> { return restinterface.GetTableUrl("AllAttachments"); }
export async function Attachments(id: number): Promise<IInstanceTable[]> { return restinterface.GetTableByIDUrl(id, "Attachments"); }
export async function UsedRoles(): Promise<IInstanceTable[]> { return restinterface.GetTableUrl("UsedRoles"); }
export async function BreadCrumbs(id: number): Promise<IRefinementTable[]> {
  return useapi ? breadcrumbs(id) : restinterface.GetTableByIDUrl(id, "BreadCrumbs");
}
export async function Refinements(id: number): Promise<IRefinementTable[]> {
  return useapi ? refinements(id) : restinterface.GetTableByIDUrl(id, "Refinements");
}
export async function Contents(id: number): Promise<INodeTable[]> {
  return restinterface.GetTableByIDUrl(id, "Contents");
}
export async function Composition(id: number): Promise<ICompositionTable> {
  return restinterface.GetTableByIDUrl(id, "Composition");
}
export async function Search(s: string, o?: number, p?: number): Promise<INodeTable[]> {
  if (o === undefined) o = 0;
  if (p === undefined || p === 0) p = 200;
  return useapi ? search(s, o, p) : restinterface.GetsTableAsyncOPUrl(s, o, p, "Search");
}
export async function SearchDiagram(id: number, s: string): Promise<INodeTable[]> {
  return useapi ? searchpage(s, id) : restinterface.GetsTableIDUrl(id, s, "SearchDiagram");
  // let res = await search(s, 0, 100000);
  // return res.filter(n=> n.NodePage===id);
}
export async function ProcTable(id: number): Promise<IActivityTable[]> {
  if (useapi) {return proctable(id); } else {
    let l = await restinterface.GetTableByIDUrl(id, "ProcTable"); 
    for (let nd of l) {
      nd.Activity = nd.ActivityCaption;
      nd.Role = nd.RoleCaption;
      nd.Input = nd.InputCaption;
      nd.Output = nd.OutputCaption;
    }
    return l;
  }
}

export async function Links(id: number): Promise<IPropertyTable[]> {
  return useapi ? links(id) : restinterface.GetTableByIDUrl(id, "Links");
}
export async function InvLinks(id: number): Promise<IPropertyTable[]> {
  return useapi ? invlinks(id) : restinterface.GetTableByIDUrl(id, "InvLinks");
}
export async function LinkedObjects(id: number, relid: number): Promise<number[]> {
  return useapi ? linkedObjects(id, relid) : restinterface.GetTableAsyncIDRelIDUrl(id, relid, "LinkedObjects");
}
export async function InvLinkedObjects(id: number, relid: number): Promise<number[]> {
  return useapi ? invlinkedObjects(id, relid) : restinterface.GetTableAsyncIDRelIDUrl(id, relid, "InvLinkedObjects");
}
export async function Instances(id: number): Promise<IInheritanceTable[]> {
  if (useapi) { return instances(id); } else {
    let l = await restinterface.GetTableByIDUrl(id, "Instances");
    for (let nd of l) {
      nd.Name = nd.ObjectName;
      nd.ObjectID = nd.ID;
    }
    return l;
  }
}
export async function AllClassInstances(id: number): Promise<IInstanceTable[]> {
  return restinterface.GetTableByIDUrl(id, "AllClassInstances");
}
export async function SubClasses(id: number): Promise<IInheritanceTable[]> {
  if (useapi) { return subclasses(id); } else {
    let l = await restinterface.GetTableByIDUrl(id, "SubClasses");
    for (let nd of l) {
      nd.Name = nd.ObjectName;
      nd.ObjectID = nd.ID;
      nd.isleaf = nd.IsLeaf;
    }
    return l;
  }
}
export async function AllSubClasses(id: number): Promise<IClassTable[]> {
  return restinterface.GetTableByIDUrl(id, "AllSubClasses");
}
export async function SuperClasses(id: number): Promise<IInheritanceTable[]> {
  if (useapi) { return superclasses(id); } else {
    let l = await restinterface.GetTableByIDUrl(id, "SuperClasses");
    for (let nd of l) {
      nd.Name = nd.ObjectName;
      nd.ObjectID = nd.ID;
    }
    return l;
  }
}
export async function AllSuperClasses(id: number): Promise<IClassTable[]> {
  return restinterface.GetTableByIDUrl(id, "AllSuperClasses");
}
export async function AllSystemClassObjects(id: number): Promise<IObjectTable[]> {
  return restinterface.GetTableByIDUrl(id, "AllSystemClassObjects");
}
export async function RoleProcesses(id: number): Promise<IDiagramTable[]> {
  return restinterface.GetTableByIDUrl(id, "RoleProcesses");
}
export async function FindSiteByID(id: number): Promise<ISiteTable | null> {
  return useapi ? getSite(id) : restinterface.GetTableByID(id, "FindSiteByID");
}
export async function FindObject(otype: string, name: string): Promise<IObjectTable | null> {
  return useapi ? findObject(name, otype) : restinterface.GetTableByTypeNameUrl(otype, name, "FindObject");
}
export async function FindObjectByID(id: number): Promise<IObjectTable | null> {
  return useapi ? getObject(id) : restinterface.GetTableByIDUrl(id, "FindObjectByID");
}
// export async function FindObjectByName(name: string): Promise<IObjectTable | null> {
//   return restinterface.GetTableByNameUrl(name, "ObjectTable");
// }
export async function FindObjectByCaption(name: string): Promise<IObjectTable | null> {
  return restinterface.GetTableByNameUrl(name, "FindObjectByCaption");
}
// export async function FindInstance(otype: string, name: string): Promise<IInstanceTable | null> {
//   return restinterface.GetTableByTypeNameUrl(otype, name, "ObjectTable");
// }
export async function FindInstanceByID(id: number): Promise<IInstanceTable | null> {
  return useapi ? getObject(id) : restinterface.GetTableByIDUrl(id, "FindInstanceByID");
}
// export async function FindClass(name: string): Promise<IClassTable | null> {
//   return restinterface.GetTableByNameUrl(name, "ObjectTable");
// }
export async function FindClassByID(id: number): Promise<IClassTable | null> {
  return useapi ? getObject(id) : restinterface.GetTableByIDUrl(id, "FindClassByID");
}
export async function FindModelByID(id: number): Promise<IModelTable | null> {
  // return restinterface.GetTableAsyncByIDUrl(id, "FindModelByID"); 
  return useapi ? getModel(id) : restinterface.GetTableByIDUrl(id, "FindModelByID");
}
export async function FindModelByName(name: string): Promise<IModelTable | null> {
  return useapi ? findModel(name) : restinterface.GetTableByNameUrl(name, "FindModelByName");
}
// export async function FindSiteByID(id: number): Promise<ISiteTable | null> {
//   return restinterface.GetTableByIDUrl(id, "SiteTable");
// }
// export async function FindSiteByName(name: string): Promise<ISiteTable | null> {
//   return restinterface.GetTableByNameUrl(name, "SiteTable");
// }
export async function FindDiagramByID(id: number): Promise<IDiagramTable | null> {
  return useapi ? getObject(id) : restinterface.GetTableByIDUrl(id, "FindDiagramByID");
}
export async function FindDiagramByNameID(name: string, modelid: number): Promise<IDiagramTable | null> {
  return useapi ? findDiagram(name, modelid) : restinterface.GetTableByNameIDUrl(name, modelid, "FindDiagramByNameID");
}
// export async function FindObjectByNameID(name: string, id: number): Promise<IObjectTable | null> {
//   return restinterface.GetTableByNameIDUrl(name, id, "ObjectTable");
// }

export async function FindReportByID(id: number): Promise<IReportTable | null> {
  return restinterface.GetTableByIDUrl(id, "FindReportByID");
}
export async function FindReportByName(name: string): Promise<IReportTable | null> {
  return restinterface.GetTableByNameUrl(name, "FindReportByName");
}

// export function FindNodeByID(id: number): NodeTable { return restinterface.GetTableByIDUrl("FindNodeByID", id); }
export async function FindNodeByID(id: number): Promise<INodeTable | null> {
  if (useapi) { return getNode(id); } else {
    let nd = await restinterface.GetTableByIDUrl(id, "FindNodeByID");
    if (nd) nd.Object = nd.ObjectID;
    return nd;
  }
}
export async function FindNodeByShape(diagid: number, s: string): Promise<INodeTable | null> {
  // return useaxios ? findNode(diagid, s): restinterface.GetsTableIDUrl(diagid, s, "FindNodeByShape");
  if (useapi) { return findNode(diagid, s); } else {
    let nd = await restinterface.GetsTableIDUrl(diagid, s, "FindNodeByShape");
    if (nd) nd.Object = nd.ObjectID;
    return nd;
  }
}
// export async function FindANodeByShapeAsync(s: string): Promise<INodeTable | null> { 
//   return restinterface.GetsTableUrl(s, "FindNodeByShape"); }
export async function NodeNames(id: number): Promise<INodeTable[]> {
  // return useaxios ? nodeNames(id):restinterface.GetTableByIDUrl(id, "NodeNames");
  if (useapi) { return nodeNames(id); } else {
    let nodes = await restinterface.GetTableByIDUrl(id, "NodeNames");
    for (let nd of nodes) {
      nd.Object = nd.ObjectID;
    }
    return nodes;
  }
}

export async function MetaModel(s: string): Promise<IClassTable | null> {
  let clid = await metamodel(s);
  if (clid) {
    return FindObjectByID(clid.Class);
  } else {
    switch (s) {
      case "SLActivity": {
        return FindObject("Class", "Task");
      }
      case "SLDataObject": {
        return FindObject("Class", "Data Object");
      }
      case "SLResource": {
        return FindObject("Class", "HumanResource");
      }
      case "SLProc": {
        return FindObject("DiagramType", "Business Process Diagram");
      }
    }
  }
  return null;
}
//export async function ModelDocumentAttributes(id: number): Promise<AttributeTable[]> { return restinterface.GetTableAsyncByIDUrl(id, "ModelAttributes"); }
export async function ModelAttributes(id: number): Promise<IModelAttributeTable[]> {
  return restinterface.GetTableByIDUrl(id, "ModelAttributes");
}
export async function ModelDiagramSVG(id: number, s: string): Promise<IModelAttributeTable | null> {
  return useapi ? diagramsvg(id, s) : restinterface.GetsTableIDUrl(id, s, "DiagramSVG");
}
export async function GetModelAttributeValue(id: number, s: string): Promise<string> {
  return restinterface.GetsTableIDUrl(id, s, "GetModelAttributeValue");
}
export async function GetModelAttributeByValue(id: number, s: string): Promise<string> {
  return restinterface.GetsTableIDUrl(id, s, "GetModelAttributeByValue");
}

export async function DiagramAttachments(id: number): Promise<IDiagramAttachment[]> {
  return useapi ? diagramattachments(id) : restinterface.GetTableByIDUrl(id, "DiagramAttachments");
}

export async function Reports(): Promise<IReportTable[]> {
  return useapi ? allReports() : restinterface.GetTableUrl("Reports");
}
export async function ReportRows(id: number): Promise<IReportRowTable[]> {
  return useapi ? reportRows(id) : restinterface.GetTableByIDUrl(id, "ReportRows");
  // return restinterface.GetTableByIDUrl(id, "ReportRows");
}
export async function ReportRowsInterval(id: number, mi: number, ma: number): Promise<IReportRowTable[]> {
  return useapi ? [] : restinterface.GetTableIDUrlmima(id, mi, ma, "ReportRowsInterval");
}
export async function ReportCellsInterval(id: number, mi: number, ma: number, colcnt: number, fi: string): Promise<IReportCellTable[]> {
  // return reportCells(id);
  const mic = mi * colcnt;
  const mac = ma * colcnt;
  let offset = mic;
  let pagesize = mac - mic;
  return useapi ? reportCellsPaged(id, offset, pagesize) : restinterface.GetTableAsyncIDUrlmimafi(id, mi, ma, fi, "ReportCellsInterval");
}
export async function ReportColumnCellsDistinct(id: number, colid: number): Promise<IReportCellTable[]> {
  return useapi ? [] : restinterface.GetTableAsyncIDUrlcolid(id, colid, "ReportColumnCellsDistinct");
}
export async function ReportRowCells(id: number, rowid: number): Promise<IReportCellTable[]> {
  return useapi ? reportRowCells(id, rowid) : restinterface.GetTableAsyncIDUrlrowid(id, rowid, "ReportRowCells");
  // return restinterface.GetTableAsyncIDUrlrowid(id, rowid, "ReportRowCells"); 
}


// export async function Folders(): Promise<IFolderElementTable[]> { return restinterface.GetTableUrl("Folders"); }
// export async function FindFolderByID(id: number): Promise<IFolderElementTable> { return restinterface.GetTableByIDUrl(id, "FindFolderByID"); }
// export async function FolderElements(id: number): Promise<IFolderElementTable[]> { return restinterface.GetTableByIDUrl(id, "FolderElements"); }

// export async function Executions(): Promise<IExecutionTable[]> { return restinterface.GetTableUrl("Executions"); }
// export async function FindExecutionByID(id: number): Promise<IExecutionTable[]> { return restinterface.GetTableByIDUrl(id, "FindExecutionByID"); }
// export async function ExecutionJobs(id: number): Promise<IJobTable[]> { return restinterface.GetTableByIDUrl(id, "ExecutionJobs"); }
// export async function ExecutionObjects(id: number): Promise<IObjectTable[]> { return restinterface.GetTableByIDUrl(id, "ExecutionObjects"); }

// export async function FindJobByID(id: number): Promise<IJobTable[]> { return restinterface.GetTableByIDUrl(id, "FindJobByID"); }
// export async function JobObjects(id: number): Promise<IObjectTable[]> { return restinterface.GetTableByIDUrl(id, "JobObjects"); }
// export async function JobTriggers(id: number): Promise<IJobTable[]> { return restinterface.GetTableByIDUrl(id, "JobTriggers"); }
// export async function JobResource(id: number): Promise<IResourceEvent[]> { return restinterface.GetTableByIDUrl(id, "JobResource"); }
// export async function JobSystems(id: number): Promise<ISystemEvent[]> { return restinterface.GetTableByIDUrl(id, "JobSystems"); }

// export async function JobSyncs(id: number): Promise<ISyncEvent[]> { return restinterface.GetTableByIDUrl(id, "JobSyncs"); }
// export async function ResourceEvents(id: number): Promise<IResourceEvent[]> { return restinterface.GetTableByIDUrl(id, "ResourceEvents"); }
// export async function SystemEvents(id: number): Promise<ISystemEvent[]> { return restinterface.GetTableByIDUrl(id, "SystemEvents"); }
// export async function SyncEvents(id: number): Promise<ISyncEvent[]> { return restinterface.GetTableByIDUrl(id, "SyncEvents"); }
