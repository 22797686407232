import { SemTalkCookie } from "../ISemTalkCookie";
import { SemTalkPortalCookie } from "../ISemTalkPortalCookie";

if ((window as any).SemTalkPortal_goto_context === undefined) {
  (window as any).SemTalkPortal_goto_context = {
    url: "",
    model: -1,
    page: -1,
    obj: -1,
    shp: "",
    callbacks: {},
    stay: false,
    exdays: 10,
    site: "",
    entity: null
  };
} else {
  console.log((window as any).SemTalkPortal_goto_context);
}
export function addCallBack(crtl: any): string {
  let cb: any = (window as any).SemTalkPortal_goto_context.callbacks;
  cb[crtl.callback] = crtl;
  return crtl.callback;
}
export function removeCallBack(crtl: any): void {
  let cb: any = (window as any).SemTalkPortal_goto_context.callbacks;
  delete cb[crtl.callback];
}
export function post2Callbacks(evt: any): void {
  let cb: any = (window as any).SemTalkPortal_goto_context.callbacks;
  for (const guid in cb) {
    if (cb) {
      let ctrl = cb[guid];
      ctrl.handleEvent(evt);
    }
  }
}

export function getContext(): any {
  return (window as any).SemTalkPortal_goto_context;
}
export function clearNode(): void {
  (window as any).SemTalkPortal_goto_context.url = "";
  (window as any).SemTalkPortal_goto_context.page = -1;
}
export function clearObject(): void {
  (window as any).SemTalkPortal_goto_context.url = "";
  (window as any).SemTalkPortal_goto_context.model = -1;
  (window as any).SemTalkPortal_goto_context.page = -1;
  (window as any).SemTalkPortal_goto_context.obj = -1;
  (window as any).SemTalkPortal_goto_context.shp = "";
}
export function getObject(): number {
  return (window as any).SemTalkPortal_goto_context.obj;
}
export function setObject(obj: number) {
  if (obj !== undefined) {
    setCookie(SemTalkCookie.contextobj, obj);
    (window as any).SemTalkPortal_goto_context.obj = obj;
  }
}
export function setEntity(entity: any) {
  (window as any).SemTalkPortal_goto_context.entity = entity;
}
export function getEntity(): any {
  return (window as any).SemTalkPortal_goto_context.entity;
}
export function getModel(): number {
  return (window as any).SemTalkPortal_goto_context.model;
}
export function setModel(obj: number): void {
  if (obj !== undefined) {
    setCookie(SemTalkCookie.contextmodel, obj);
    (window as any).SemTalkPortal_goto_context.model = obj;
  }
}
export function setStay(_stay: boolean): void {
  (window as any).SemTalkPortal_goto_context.stay = _stay;
}
// export function setSite(site: string): void {
//   if (site !== undefined) {
//     setCookie("contextsite", site.toString());
//     (window as any).SemTalkPortal_goto_context.site = site;
//   }
// }
export function getDiagram(): number {
  return (window as any).SemTalkPortal_goto_context.page;
}
export function setDiagram(obj: number): void {
  if (obj !== undefined) {
    setCookie(SemTalkCookie.contextpage, obj);
    (window as any).SemTalkPortal_goto_context.page = obj;
  }
}
// export function getURL(): string {
//   return (window as any).SemTalkPortal_goto_context.url;
// }
// export function setURL(_obj: string) {
//   setCookie ("contexturl", _obj);
//  (window as any).SemTalkPortal_goto_context.url = _obj;
// }
export function getShape(): string {
  return (window as any).SemTalkPortal_goto_context.shp;
}
export function setShape(obj: string) {
  if (obj !== undefined) {
    setCookie(SemTalkCookie.contextshp, obj);
    (window as any).SemTalkPortal_goto_context.shp = obj;
  }
}
export function setCookie(cname: SemTalkCookie | SemTalkPortalCookie, cvalue: any) {
  (window as any).localStorage.setItem("SemTalkPortal_" + cname, cvalue);
}
export function accessCookie(cookieName: SemTalkCookie | SemTalkPortalCookie): any {
  return (window as any).localStorage.getItem("SemTalkPortal_" + cookieName);
}
export function removeCookie(cookieName: SemTalkCookie | SemTalkPortalCookie): any {
  return (window as any).localStorage.removeItem("SemTalkPortal_" + cookieName);
}