// import { SemTalkID } from './application/tbase/Interface';
import { accessCookie, removeCookie, setCookie } from './application/stglobal';
import { SemTalkCookie } from './ISemTalkCookie';

export interface IHistoryEntry {
    "filename": string;
    "id": number;
}
export interface IHistory {
    "history": { [key: string]: IHistoryEntry };
}

export function resetHistory(): void {
    removeCookie(SemTalkCookie.history);
    removeCookie(SemTalkCookie.historypos);
}
export function addDocumentToHistory(filename: string, id: number) {
    if (filename !== "") {
        let historyEntry: IHistoryEntry = {
            "filename": filename,
            "id": id
        };
        let pos: string | null = accessCookie(SemTalkCookie.historypos);
        let cs: number = 1;
        let entries: IHistory;
        let add: boolean = false;
        let max = 20;
        let h: string | null = accessCookie(SemTalkCookie.history);
        if (!h) {
            entries = {
                history:
                {
                }
            };
            add = true;

        } else {
            entries = JSON.parse(h);
            let lastkey = Number(Object.keys(entries.history).pop());
            cs = lastkey + 1;
            if ((entries.history[lastkey] && entries.history[lastkey].id === id) || (pos !== null && entries.history[pos].id === id)) {

            } else {
                if (cs > max) {
                    let entriesNew = { history: {} };
                    for (let i = 1; i < max; i++) {
                        (entriesNew.history as any)[i] = entries.history[i + 1];
                    }
                    entries = entriesNew;
                    cs = lastkey;
                }
                add = true;
            }

        }
        if (add) {
            entries.history[cs] = historyEntry;
            setCookie(SemTalkCookie.history, JSON.stringify(entries));
            setCookie(SemTalkCookie.historypos, String(cs));
        }
    }

}

export function isBackward(): boolean {
    let hp = accessCookie(SemTalkCookie.historypos);
    return (hp !== null && Number(hp) > 0);
}

export function isForward(): boolean {
    let hp = accessCookie(SemTalkCookie.historypos);
    if (hp !== null) {
        let f = accessCookie(SemTalkCookie.history);
        if (f) {
            let entries: IHistory = JSON.parse(f);
            return (Number(hp) < Object.keys(entries.history).length);
        }
    }
    return false;
}