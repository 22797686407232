/* eslint-disable require-atomic-updates */
import axios from 'axios';
import {
    // FindObjectByID,
    IActivityTable,
    IClassTable,
    IDiagramAttachment,
    IDiagramTable, IInheritanceTable,
    // IInstanceTable, 
    IModelAttributeTable, IModelTable,
    INodeTable, IObjectTable, IPropertyTable, IRefinementTable, IReportCellTable, IReportRowTable, IReportTable, ISiteTable, IVariableTable
} from './dbase';
// import { GetTableAsync1 } from './components/application/restinterface';


// const srv = "https://semportalapi.azurewebsites.net";
const srv = "";

export function resetcachesapi() {
    sitecache = {};
    modelcache = {};
    objectcache = {};
    objectnamecache = {};
    objectscache = {};
    diagramscache = {};
    rootdiagramscache = {};
    svgcache = {};
    modelattributescache = {};
    diagramnodescache = {};
    diagramattachmentscache = {};
    nodenamescache = {};
    linkscache = {};
    linkedobjscache = {};
    invlinkedobjscache = {};
    invlinkscache = {};
    breadcrumbcache = {};
    rootclassescache = {};
    metamodelcache = {};
    reportscache = [];
    reportcellcache = {};
 }

let sitecache: { [id: string]: ISiteTable } = {};
export async function getSite(id: number): Promise<ISiteTable | null> {
    let c = sitecache["_" + id];
    if (c) {
        return c;
    }
    let url = srv + "/api/site/" + id.toString();
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            sitecache["_" + id] = res.data[0];
            return res.data[0];
        } else {
            console.log("getSite: " + res.statusText);
            return null;
        }
        // return res;
    } catch (e) {
        console.log("getSite: " + e);
        return null;
    }
}
export async function allSites(): Promise<ISiteTable[]> {
    let url = srv + "/api/site";
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            return res.data;
        } else {
            console.log("allSites: " + res.statusText);
            return [];
        }
        // return res;
    } catch (e) {
        console.log("allSites: " + e);
        return [];
    }
}

let modelcache: { [id: string]: IModelTable } = {};
export async function getModel(id: number): Promise<IModelTable | null> {
    let c = modelcache["_" + id];
    if (c) {
        return c;
    }
    let url = srv + "/api/model/" + id.toString();
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            modelcache["_" + id] = res.data[0];
            return res.data[0];
        } else {
            console.log("getModel: " + res.statusText);
            return null;
        }
        // return res;
    } catch (e) {
        console.log("getModel: " + e);
        return null;
    }
}
export async function allModels(): Promise<IModelTable[]> {
    let url = srv + "/api/model";
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            return res.data;
        } else {
            console.log("allModels: " + res.statusText);
            return [];
        }
        // return res;
    } catch (e) {
        console.log("allModels: " + e);
        return [];
    }
}
export async function findModel(name: string): Promise<IModelTable | null> {
    let modellist = await allModels();
    let di = modellist.find(n => n.ModelName === name);
    if (di) return di;
    return null;
}

let objectcache: { [id: string]: IObjectTable } = {};
export async function getObject(id: number): Promise<IObjectTable | null> {
    let c = objectcache["_" + id];
    if (c) {
        return c;
    }
    let url = srv + "/api/object/" + id.toString();
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            objectcache["_" + id] = res.data[0];
            return res.data[0];
        } else {
            console.log("getObjectByID: " + res.statusText);
            return null;
        }
        // return res;
    } catch (e) {
        console.log("getObject: " + e);
        return null;
    }
}
let objectnamecache: { [id: string]: IObjectTable } = {};
export async function findObject(name: string, type: string): Promise<IObjectTable | null> {
    let ename = encodeURIComponent(name);
    let c = objectnamecache[type + "_" + ename];
    if (c) {
        return c;
    }
    let url = srv + "/api/object/" + ename + "/type/" + type;
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            objectnamecache[type + "_" + ename] = res.data[0];
            return res.data[0];
        } else {
            console.log("getObjectByID: " + res.statusText);
            return null;
        }
        // return res;
    } catch (e) {
        console.log("getObject: " + e);
        return null;
    }
    // let objectslist = await allObjects(type);
    // let di = objectslist.find(n => n.ObjectCaption === name);
    // if (di) return di;
    // return null;
}
let objectscache: { [id: string]: IObjectTable[] } = {};
export async function allObjects(type: string): Promise<IDiagramTable[]> {
    let c = objectscache["_" + type];
    if (c) {
        return c;
    }
    let url = srv + "/api/objects/type/" + type;
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            objectscache["_" + type] = res.data;
            return res.data;
        } else {
            console.log("allObjects: " + res.statusText);
            return [];
        }
        // return res;
    } catch (e) {
        console.log("allObjects: " + e);
        return [];
    }
}
export async function findDiagram(name: string, _modelid: number): Promise<IDiagramTable | null> {
    let diagramlist = await allDiagrams();
    let di = diagramlist.find(n => n.ObjectCaption === name);
    if (di) return di;
    return null;
}
let diagramscache: { [id: string]: IDiagramTable[] } = {};
export async function allDiagrams(): Promise<IDiagramTable[]> {
    let c = diagramscache["_"];
    if (c) {
        return c;
    }
    let url = srv + "/api/diagrams";
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            diagramscache["_"] = res.data;
            return res.data;
        } else {
            console.log("allDiagrams: " + res.statusText);
            return [];
        }
        // return res;
    } catch (e) {
        console.log("allDiagrams: " + e);
        return [];
    }
}
let rootdiagramscache: { [id: string]: IDiagramTable[] } = {};
export async function allRootDiagrams(diagtypeid: number): Promise<IDiagramTable[]> {
    let c = rootdiagramscache["_" + diagtypeid];
    if (c) {
        return c;
    }
    let url = srv + "/api/allrootdiagrams/diagtype/" + diagtypeid;
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            rootdiagramscache["_" + diagtypeid] = res.data;
            return res.data;
        } else {
            console.log("allRootDiagrams: " + res.statusText);
            return [];
        }
        // return res;
    } catch (e) {
        console.log("allRootDiagrams: " + e);
        return [];
    }
}
let modelattributescache: { [id: string]: IModelAttributeTable[] } = {};
let svgcache: { [id: string]: IModelAttributeTable } = {};
export async function diagramsvg(modelid: number, diagcaption: string): Promise<IModelAttributeTable | null> {
    let svg = svgcache["diagcaption" + modelid];
    if (svg) {
        return svg;
    }
    let modelattributes = modelattributescache["_" + modelid];
    if (!modelattributes) {
        let url = srv + "/api/modelattribute/" + modelid;
        try {
            let res = await axios.get(url);
            if (res.status === 200 && res.data && res.data.length > 0) {
                modelattributes = res.data;
                modelattributescache["_" + modelid] = modelattributes;
            } else {
                console.log("modelattribute: " + res.statusText);
                return null;
            }
            // return res;
        } catch (e) {
            console.log("modelattribute: " + e);
            return null;
        }
    }
    if (modelattributes) {
        let m1 = modelattributes.find(m => m.AttributeTypeName.startsWith("SVG_") &&
            m.AttributeValue === diagcaption);
        if (m1) {
            let aname = m1.AttributeTypeName.replace("_Page", "_");
            let m2 = modelattributes.find(m => m.AttributeTypeName === aname);
            if (m2) {
                svgcache["_" + modelid] = m2;
                return m2;
            }
        }
    }
    return null;
}
export async function getNode(id: number): Promise<INodeTable | null> {
    let url = srv + "/api/node/" + id.toString();
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            return res.data[0];
        } else {
            console.log("getNode: " + res.statusText);
            return null;
        }
        // return res;
    } catch (e) {
        console.log("getNode: " + e);
        return null;
    }
}

let diagramnodescache: { [id: string]: INodeTable[] } = {};
export async function diagramnodes(id: number): Promise<INodeTable[]> {
    let c = diagramnodescache["_" + id];
    if (c) {
        return c;
    }
    let url = srv + "/api/diagramnodes/" + id;
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            diagramnodescache["_" + id] = res.data;
            return res.data;
        } else {
            console.log("diagramnodes: " + res.statusText);
            return [];
        }
        // return res;
    } catch (e) {
        console.log("diagramnodes: " + e);
        return [];
    }
}
let diagramattachmentscache: { [id: string]: IDiagramAttachment[] } = {};
export async function diagramattachments(id: number): Promise<IDiagramAttachment[]> {
    let c = diagramattachmentscache["_" + id];
    if (c) {
        return c;
    }
    let url = srv + "/api/diagramattachments/" + id;
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            diagramattachmentscache["_" + id] = res.data;
            return res.data;
        } else {
            console.log("diagramattachments: " + res.statusText);
            return [];
        }
        // return res;
    } catch (e) {
        console.log("diagramattachments: " + e);
        return [];
    }
}
export async function findNode(diagid: number, shp: string): Promise<INodeTable | null> {
    let diagramnodelist = await diagramnodes(diagid);
    let nd = diagramnodelist.find(n => n.NodeShape === shp);
    if (nd) return nd;
    return null;
}
let nodenamescache: { [id: string]: INodeTable[] } = {};
export async function nodeNames(id: number): Promise<INodeTable[]> {
    let c = nodenamescache["_" + id];
    if (c) {
        return c;
    }
    let url = srv + "/api/nodenames/" + id;
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            nodenamescache["_" + id] = res.data;
            return res.data;
        } else {
            console.log("nodenames: " + res.statusText);
            return [];
        }
        // return res;
    } catch (e) {
        console.log("nodenames: " + e);
        nodenamescache["_" + id] = [];
        return [];
    }
}
let linkscache: { [id: string]: IPropertyTable[] } = {};
export async function links(id: number): Promise<IPropertyTable[]> {
    let c = linkscache["_" + id];
    if (c) {
        return c;
    }
    let url = srv + "/api/links/" + id;
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            linkscache["_" + id] = res.data;
            return res.data;
        } else {
            console.log("links: " + res.statusText);
            return [];
        }
        // return res;
    } catch (e) {
        console.log("links: " + e);
        linkscache["_" + id] = [];
        return [];
    }
}
let linkedobjscache: { [id: string]: number[] } = {};
export async function linkedObjects(id: number, relid: number): Promise<number[]> {
    let c = linkedobjscache["_" + id + "_" + relid];
    if (c) {
        return c;
    }
    let url = srv + "/api/linkedobjects/" + id + "/relation/" + relid;
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            let lks = res.data;
            let lksobjs = lks.map(async (x: any) => x["ObjectID"]);
            linkedobjscache["_" + id + "_" + relid] = lksobjs;
            return lksobjs;
        } else {
            console.log("linkedobjects: " + res.statusText);
            return [];
        }
        // return res;
    } catch (e) {
        console.log("linkedobjects: " + e);
        linkedobjscache["_" + id + "_" + relid] = [];
        return [];
    }
}
let invlinkedobjscache: { [id: string]: number[] } = {};
export async function invlinkedObjects(id: number, relid: number): Promise<number[]> {
    let c = invlinkedobjscache["_" + id + "_" + relid];
    if (c) {
        return c;
    }
    let url = srv + "/api/invlinkedobjects/" + id + "/relation/" + relid;
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            let lks = await res.data;
            let lksobjs = lks.map((x: any) => x["ID"]);
            invlinkedobjscache["_" + id + "_" + relid] = lksobjs;
            return lksobjs;
        } else {
            console.log("invlinkedobjects: " + res.statusText);
            return [];
        }
        // return res;
    } catch (e) {
        console.log("invlinkedobjects: " + e);
        invlinkedobjscache["_" + id + "_" + relid] = [];
        return [];
    }
}

let invlinkscache: { [id: string]: IPropertyTable[] } = {};
export async function invlinks(id: number): Promise<IPropertyTable[]> {
    let c = invlinkscache["_" + id];
    if (c) {
        return c;
    }
    let url = srv + "/api/invlinks/" + id;
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            linkscache["_" + id] = res.data;
            return res.data;
        } else {
            console.log("invlinks: " + res.statusText);
            return [];
        }
        // return res;
    } catch (e) {
        console.log("invlinks: " + e);
        invlinkscache["_" + id] = [];
        return [];
    }
}
export async function refinements(id: number): Promise<IRefinementTable[]> {
    let url = srv + "/api/refinements/" + id;
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            return res.data;
        } else {
            if (res.status !== 200) {
                console.log("refinements: " + res.statusText);
            }
            return [];
        }
        // return res;
    } catch (e) {
        console.log("refinements: " + e);
        return [];
    }
}
export async function invrefinements(id: number): Promise<IRefinementTable[]> {
    let url = srv + "/api/invrefinements/" + id;
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            return res.data;
        } else {
            if (res.status !== 200) {
                console.log("invrefinements: " + res.statusText);
            }
            return [];
        }
        // return res;
    } catch (e) {
        console.log("invrefinements: " + e);
        return [];
    }
}
let breadcrumbcache: { [id: string]: IRefinementTable[] } = {};
export async function breadcrumbs(id: number): Promise<IRefinementTable[]> {
    let c = breadcrumbcache["_" + id];
    if (c) {
        return c;
    }
    let irefs = await invrefinements(id);
    if (irefs.length > 0) {
        let par = irefs[0];
        // if (breadcrumbcache["_" + par.ID] === undefined) {
        let pb = await breadcrumbs(par.ID);
        irefs.push(...pb);
        // } else {
        //     let pb = breadcrumbcache["_" + par.ID];
        //     return irefs.concat(pb);    
        // }
    }
    breadcrumbcache["_" + id] = irefs;
    return irefs;
}
export async function instances(id: number): Promise<IInheritanceTable[]> {
    let url = srv + "/api/instances/" + id;
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            return res.data;
        } else {
            if (res.status !== 200) {
                console.log("instances: " + res.statusText);
            }
            return [];
        }
        // return res;
    } catch (e) {
        console.log("instances: " + e);
        return [];
    }
}
export async function subclasses(id: number): Promise<IInheritanceTable[]> {
    let url = srv + "/api/subclasses/" + id;
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            return res.data;
        } else {
            if (res.status !== 200) {
                console.log("subclasses: " + res.statusText);
            }
            return [];
        }
        // return res;
    } catch (e) {
        console.log("subclasses: " + e);
        return [];
    }
}
export async function superclasses(id: number): Promise<IInheritanceTable[]> {
    let url = srv + "/api/superclasses/" + id;
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            return res.data;
        } else {
            if (res.status !== 200) {
                console.log("superclasses: " + res.statusText);
            }
            return [];
        }
        // return res;
    } catch (e) {
        console.log("superclasses: " + e);
        return [];
    }
}
let rootclassescache: { [id: string]: IClassTable[] } = {};
export async function allRootClasses(): Promise<IClassTable[]> {
    let c = rootclassescache["_"];
    if (c) {
        return c;
    }
    let url = srv + "/api/allrootclasses/";
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            rootdiagramscache["_"] = res.data;
            return res.data;
        } else {
            console.log("allRootClasses: " + res.statusText);
            return [];
        }
        // return res;
    } catch (e) {
        console.log("allRootClasses: " + e);
        return [];
    }
}
export async function proctable(id: number): Promise<IActivityTable[]> {
    let url = srv + "/api/proctable/" + id;
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            return res.data;
        } else {
            if (res.status !== 200) {
                console.log("proctable: " + res.statusText);
            }
            return [];
        }
        // return res;
    } catch (e) {
        console.log("proctable: " + e);
        return [];
    }
}
export async function search(s: string, _o: number, _p: number): Promise<INodeTable[]> {
    let url = srv + "/api/search/" + encodeURIComponent("%" + s + "%");
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            return res.data;
        } else {
            if (res.status !== 200) {
                console.log("search: " + res.statusText);
            }
            return [];
        }
        // return res;
    } catch (e) {
        console.log("search: " + e);
        return [];
    }
}
export async function searchpage(s: string, p: number): Promise<INodeTable[]> {
    let url = srv + "/api/searchpage/" + encodeURIComponent("%" + s + "%") + "/page/" + p;
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            return res.data;
        } else {
            if (res.status !== 200) {
                console.log("searchpage: " + res.statusText);
            }
            return [];
        }
        // return res;
    } catch (e) {
        console.log("searchpage: " + e);
        return [];
    }
}

let metamodelcache: { [id: string]: IVariableTable } = {};
export async function metamodel(s: string): Promise<IVariableTable | null> {
    let c = metamodelcache[s];
    if (c) {
        return c;
    }
    let url = srv + "/api/metamodel/" + encodeURIComponent(s);
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            metamodelcache[s] = res.data[0];
            return res.data[0];
        } else {
            if (res.status !== 200) {
                console.log("metamodel: " + res.statusText);
            }
            return null;
        }
        // return res;
    } catch (e) {
        console.log("metamodel: " + e);
        return null;
    }
}

let reportscache: IReportTable[] = [];
export async function allReports(): Promise<IReportTable[]> {
    if (reportscache.length > 0) {
        return reportscache;
    }

    let url = srv + "/api/report";
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            reportscache = res.data;
            return res.data;
        } else {
            console.log("allReports: " + res.statusText);
            return [];
        }
        // return res;
    } catch (e) {
        console.log("allReports: " + e);
        return [];
    }
}
export async function reportRowCells(reportid: number, rowid: number): Promise<IReportCellTable[]> {
    let url = srv + "/api/report/" + reportid + "/rows/" + rowid + "/cells";
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            return res.data;
        } else {
            console.log("reportRowCells: " + res.statusText);
            return [];
        }
        // return res;
    } catch (e) {
        console.log("reportRowCells: " + e);
        return [];
    }
}
let reportcellcache: { [id: string]: IReportCellTable[] } = {};
export async function reportCells(reportid: number): Promise<IReportCellTable[]> {
    let c = reportcellcache["_" + reportid];
    if (c) {
        return c;
    }
    let url = srv + "/api/report/" + reportid + "/cells";
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            reportcellcache["_" + reportid] = res.data;
            return res.data;
        } else {
            console.log("reportCells: " + res.statusText);
            return [];
        }
        // return res;
    } catch (e) {
        console.log("reportCells: " + e);
        return [];
    }
}

export async function reportCellsPaged(reportid: number, offset: number, pagesize: number): Promise<IReportCellTable[]> {
    let cells = await reportCells(reportid);
    let l = cells.length;
    if (offset < l) {
        if (offset + pagesize <= l) {
            return cells.slice(offset, offset + pagesize);
        } else {
            return cells.slice(offset);
        }
    }
    return [];

    // let url = "/api/report/" + reportid + "/offset/" + offset + "/pagesize/" + pagesize + "/cells";
    // try {
    //     let res = await axios.get(url);
    //     if (res.status === 200 && res.data && res.data.length > 0) {
    //         for (let i = )
    //         return res.data;
    //     } else {
    //         console.log("reportCellsPaged: " + res.statusText);
    //         return [];
    //     }
    //     // return res;
    // } catch (e) {
    //     console.log("reportCellsPaged: " + e);
    //     return [];
    // }
}
export async function reportRows(reportid: number): Promise<IReportRowTable[]> {
    let url = srv + "/api/report/" + reportid + "/rows";
    try {
        let res = await axios.get(url);
        if (res.status === 200 && res.data && res.data.length > 0) {
            return res.data;
        } else {
            console.log("reportRowCells: " + res.statusText);
            return [];
        }
        // return res;
    } catch (e) {
        console.log("reportRowCells: " + e);
        return [];
    }
}
