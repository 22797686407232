import * as React from 'react';
import styles from './SemTalkReportFilter.module.scss';
import {
  IColumn, DetailsList, DetailsListLayoutMode, CheckboxVisibility,
  Dropdown, IDropdownOption, DefaultButton, CommandBar, ComboBox, IComboBoxOption
} from 'office-ui-fabric-react';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { SelectionMode } from 'office-ui-fabric-react/lib/utilities/selection/index';
// import { SetContext } from '../../application/restinterface';

import { ReportRowCells, IReportCellTable, ReportColumnCellsDistinct } from '../../application/dbase';
// import { WebPartContext } from '@microsoft/sp-webpart-base';
// import * as strings from 'SemTalkStrings';
import { ISemService } from '../../../options';

export interface ISemTalkReportFilterProps {
  semservice: ISemService;
  strings: ISemTalkStrings;
  filters: IFilter[];
  reportid: number;
  applyfilter: (filter: string, filters: IFilter[]) => void;
}
export interface IFilter {
  colid: number;
  column: string;
  //  operator: string;
  value: string;
  objectid: number;

}

export interface ISemTalkReportFilterState {
  columns: IColumn[];
  rescolumns: IReportCellTable[];
  filters: IFilter[];
  values: IReportCellTable[];
  newitem: boolean;
  edititem: boolean;
  item: IFilter;
}

export class SemTalkReportFilter extends React.Component<ISemTalkReportFilterProps, ISemTalkReportFilterState> {
  // private _columns: IColumn[] = [];
  // private _selection: Selection;
  private _filter: number | undefined;
  constructor(props: ISemTalkReportFilterProps) {
    super(props);
    console.log("SemTalkReportFilter");
    this.state = {
      values: [],
      columns: [],
      rescolumns: [],
      newitem: false,
      edititem: false,
      filters: this.props.filters,
      item: {
        colid: 0,
        column: "",
        //   operator: "=",
        value: "",
        objectid: 0
      }
    };
  }


  private deletefilter = (): void => {
    if (this._filter !== undefined) {
      let filters = this.state.filters;
      filters.splice(this._filter, 1);
      const item = {
        colid: 0,
        column: "",
        objectid: 0,
        value: ""
      };
      this.props.applyfilter(this.buildFilter(filters), this.state.filters);
      this.setState({ item: item, values: [], newitem: false, edititem: false, filters: filters });
    }
  }
  private buildFilter(filters: IFilter[]): string {
    let f: any = {};
    for (let filter of filters) {
      if (filter.objectid > 0) {
        f[filter.colid] = filter.objectid;
      } else {
        f[filter.colid] = filter.value;
      }
    }
    return JSON.stringify(f);
  }
  private addfilter = async () => {
    let item = {
      colid: 0,
      column: "",
      objectid: 0,
      value: ""
    };
    if (this.state.columns.length > 0) {
      item.column = this.state.columns[item.colid].name;
    }
    let values = await ReportColumnCellsDistinct(this.props.reportid, item.colid);
    values = values.sort(this.comparecells);
    this.setState({ item: item, values: values, newitem: true, edititem: false });
  }
  private editfilter = async () => {
    // if (this._selection && this._selection.getSelection().length > 0) {
    if (this._filter) {
      let item: IFilter = this.state.filters[this._filter]; //this._selection.getSelection()[0] as IFilter;
      let values = await ReportColumnCellsDistinct(this.props.reportid, item.colid);
      values = values.sort(this.comparecells);
      this.setState({ item: item, values: values, newitem: false, edititem: true });
    }
  }

  private comparecells = (c1: IReportCellTable, c2: IReportCellTable) => {
    let cap1 = "";
    if (c1.ObjectID > 0) {
      cap1 = c1.ValueCaption;
    } else {
      cap1 = c1.Value;
      if (c1.ValueCaption.length > 0) {
        cap1 = c1.ValueCaption;
      }
      if (cap1.indexOf("://") > 0) {
        cap1 = c1.Value;
      }
    }
    let cap2 = "";
    if (c2.ObjectID > 0) {
      cap2 = c2.ValueCaption;
    } else {
      cap2 = c2.Value;
      if (c2.ValueCaption.length > 0) {
        cap2 = c2.ValueCaption;
      }
      if (cap2.indexOf("://") > 0) {
        cap2 = c2.Value;
      }
    }
    return cap1.localeCompare(cap2);
  }

  private commands = [
    {
      key: 'Add',
      name: this.props.strings.Add,
      iconProps: {
        iconName: 'Add'
      },
      onClick: this.addfilter
    },
    {
      key: 'Edit',
      name: this.props.strings.Edit,
      iconProps: {
        iconName: 'Edit'
      },
      onClick: this.editfilter
    },
    {
      key: 'Delete',
      name: this.props.strings.Delete,
      iconProps: {
        iconName: 'Delete'
      },
      onClick: this.deletefilter
    }];

  private filtercolumns: IColumn[] = [
    {
      key: 'Column',
      name: 'Column',
      fieldName: 'column',
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      isMultiline: true,
    },
    {
      key: 'Value',
      name: 'Value',
      fieldName: 'value',
      minWidth: 50,
      // maxWidth: 180,
      isResizable: true,
      isMultiline: true,
    }
  ];

  public render(): React.ReactElement<ISemTalkReportFilterProps> {

    let column_options: any[] = this.state.columns.map((d: IColumn) => {
      return {
        key: d.key,
        text: d.name,
        // value: d
      };
    });

    let valueindex: number = -1;
    let value_options: any[] = this.state.values.map(vcell => {
      valueindex = valueindex + 1;
      let cap = "";
      if (vcell.ObjectID > 0) {
        cap = vcell.ValueCaption;
      } else {
        cap = vcell.Value;
        if (vcell.ValueCaption.length > 0) {
          cap = vcell.ValueCaption;
        }
        if (cap.indexOf("://") > 0) {
          cap = vcell.Value;
        }
      }
      return {
        key: valueindex,
        text: cap,
        //value: vcell
      };
    });

    return (
      <Fabric>
        {!this.state.newitem &&
          <div className={styles.semTalkReportFilter}>
            <CommandBar
              items={this.commands}
             />
            <DetailsList
              // ref={this._resolveList}
              items={this.state.filters}
              // compact={true}
              columns={this.filtercolumns}
              selectionMode={SelectionMode.single}
              // setKey="set"
              layoutMode={DetailsListLayoutMode.justified}
              // selection={this._selection}
              checkboxVisibility={CheckboxVisibility.hidden}
              onActiveItemChanged={this.setFilter}
            />
          </div>
        }

        {(this.state.newitem || this.state.edititem) &&
          <div>
            <div>
              <div>
                <Dropdown
                  // ref={this._resolveList}
                  label={this.props.strings.Column}
                  defaultSelectedKey={this.state.item.colid.toString()}
                  onChanged={this.onOptionColumnChange}
                  options={column_options}
                />
              </div>
              <div>
                <ComboBox
                  // ref={this._resolveList}
                  label={this.props.strings.Value}
                  allowFreeform
                  autoComplete="on"
                  text={this.state.item.value}
                  onChange={this.onOptionValueChange}
                  options={value_options}
                />
              </div>
              <div>
                <DefaultButton text={this.props.strings.Submit} onClick={this.addnewitem} />
                <DefaultButton text={this.props.strings.Cancel} onClick={this.cancelnew} />
              </div>
            </div>
          </div>
        }
      </Fabric>
    );
  }
  private setFilter = (_item: any, index: number | undefined): void => {
    this._filter = index;
  }

  private onOptionColumnChange = async (option: IDropdownOption, _index?: number, _value?: any) => {
    if (option) {
      let item = this.state.item;
      item.column = option.text;
      let key: string = option.key as string;
      let colid: number = parseInt(key);
      item.colid = colid;
      let values = await ReportColumnCellsDistinct(this.props.reportid, colid);
      values = values.sort(this.comparecells);
      this.setState({ item: item, values: values });
    }
  }

  private onOptionValueChange = (_ev: any, option: IComboBoxOption | undefined, _index?: number, value?: string) => {
    let item = this.state.item;
    if (option) {
      item.value = option.text;
      let valueid = option.key as number;
      item.objectid = this.state.values[valueid].ObjectID;
    } else {
      if (value) {
        item.value = value;
      }
    }
    this.setState({ item: this.state.item });
  }
  private addnewitem = (): void => {
    let item = this.state.item;
    if (item.column.length > 0 && item.value.length > 0) {
      // this.addListItem(this.state.listTitle, this.newitem.title, this.newitem.description).then(() => {
      let f = this.state.filters;
      if (!this.state.edititem) {
        f = f.concat([{ colid: item.colid, column: item.column, objectid: item.objectid, value: item.value }]);
      }
      item = {
        colid: 0,
        column: "",
        objectid: 0,
        value: ""
      };
      this.setState({
        item: item,
        newitem: false,
        edititem: false,
        filters: f
      });
      this.props.applyfilter(this.buildFilter(f), f);
      // });
    } else {
      item = {
        colid: 0,
        column: "",
        objectid: 0,
        value: ""
      };
      this.setState({
        item: item,
        newitem: false,
        edititem: false,
      });
    }
  }
  private cancelnew = (): void => {
    let item = {
      colid: 0,
      column: "",
      objectid: 0,
      value: ""
    };
    this.setState({
      item: item,
      newitem: false,
      edititem: false,
    });
  }

  // private mounted: boolean = false;
  public async componentDidMount() {
    // SetContext(this.props.semservice);
    await this.RunReport(this.props.reportid, 0, 1, "");
  }

  public componentWillUnmount() {
    // this.mounted = false;
  }
  private RunReport = async (reportid: number, _minrow: number, _maxrow: number, filter: string) => {
    // this.setState({
    //   columns: [],
    //   rescolumns: [],
    // });
    if (reportid < 0) return;

    if (filter === undefined) {
      filter = "";
    }
    let _columns: IColumn[] = [];
    let cols = await ReportRowCells(reportid, 0);
    for (let cell of cols) {
      _columns.push(
        {
          key: cell.ColumnID.toString(),
          name: cell.Value,
          fieldName: cell.ColumnID.toString(),
          minWidth: 100,
        });
    }
    this.setState({
      columns: _columns,
      // rescolumns: _rescols,
    });
  }
}
