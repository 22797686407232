import * as React from 'react';
// import styles from './SemTalkProperty.module.scss';
import {
  Stack, Link, Selection, IColumn, DetailsList, DetailsListLayoutMode, CheckboxVisibility,
  // DefaultButton, 
  Text, StackItem, Label
} from 'office-ui-fabric-react';
// import { Icon } from 'office-ui-fabric-react/lib/Icon';

import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { SelectionMode } from 'office-ui-fabric-react/lib/utilities/selection/index';

import {
  Links,
  IPropertyTable, IObjectTable,
  FindObjectByID,
  // FindObjectByName,
  // INodeTable, 
  NodeNames,
  // MetaModel,
  // IClassTable, 
  // FindDiagramByNameID, 
  // FindDiagramByID,
  // Instances, 
  INodeTable
} from '../../application/dbase';
// import { ClassTable, MetaModel } from '../../semtalkportal/dbase';
import {
  gotoNode, gotoObject, gotoDocument, getObject, addCallBack,
  removeCallBack, getModel, accessCookie,
  // gotoEntity
} from '../../application/stglobal';
// import { NavigateForward, NavigateBackward } from '../../application/stbackforward';
// import { WebPartContext } from '@microsoft/sp-webpart-base';
// import { SetContext } from '../../application/restinterface';
// import { Channel, DriveItem, Entity, PlannerBucket, PlannerTask } from '@microsoft/microsoft-graph-types';

// import { autobind } from 'office-ui-fabric-react';
import { Guid } from "guid-typescript";
import { ISemService } from '../../../options';
import { SemTalkPortalCookie } from '../../ISemTalkPortalCookie';
import { IResStr } from '../../application/resstr';
import { getDiagram, getShape } from '../../application/stgoto';
// import { getDiagram } from '../../application/stgoto';
// import * as strings from 'SemTalkStrings';
// import { getEntity } from '../../application/stgoto';
// import { MSGraphClient } from '@microsoft/sp-http';
// import { fetchGraph, EntityCaption, GraphPropertyTable, GraphProperties, GraphAttributes } from '../../semtalkportal/stmsgraph';
// import { SPHttpClient } from '@microsoft/sp-http';
// import { SPListItem } from '@microsoft/sp-page-context';
// import { User, DirectoryObject, Group, PlannerPlan } from '@microsoft/microsoft-graph-types';


export interface ISemTalkPropertyTableProps {
  semservice: ISemService;
  res: IResStr;
  // goodlist?: string[];
  // badlist?: string[];
  // showtype?: boolean;
  // showproperties?: boolean;
  // shownav?: boolean;
  // navigateproperties: boolean;
  hidebpmn?: string[];
  hidesimulation?: string[];
  // goto_context?: any;
  // graphClient?: any;
  // site?: string;
  // listTitle?: string;
  // query?: string;
  // usegraph?: boolean;
  // strings: ISemTalkStrings;
}
export interface ISemTalkPropertyTableState {
  objectid: number;
  items: any[];
  hasError: boolean;
  // isact: boolean;
  // isres: boolean;
  // goto_context?: any;
  // filter: string;
  // entity: Entity | null;
  // entitytype: string;
  object: IObjectTable | null;
  // model: number | null;
  // rolemappings: SPListItem[];
  // group: Group | null;
  // groupMembers: DirectoryObject[];
  // groupPlans: PlannerPlan[];
  // grouploaded: boolean;
  // rolesloaded: boolean;
  gotonodes: boolean;
  shownodes: boolean;
  showproperties: boolean;
}
// let thisdiag: number = -1;
// async function _navigate(id: number) {
//   // console.log("Navigate: ", id);
//   let nn = await NodeNames(id);
//   for (let n of nn) {
//     if (n.NodePage === thisdiag) {
//       await gotoNode(n.ID, n.Object, n.NodePage, n.NodeShape);
//       return;
//     }
//   }
// }
export default class SemTalkPropertyTable extends React.Component<ISemTalkPropertyTableProps, ISemTalkPropertyTableState> {
  private _selection: Selection;
  public callback: Guid;
  private navigateproperties: boolean = true;
  // private resourceclasses: number[] = [];
  // private activityclasses: number[] = [];

  constructor(props: ISemTalkPropertyTableProps) {
    super(props);
    console.debug("showPropertyTable");
    // _gotonodes = this.props.gotonodes;
    this._selection = new Selection({
    });
    let gnd = accessCookie(SemTalkPortalCookie.props_gotonodes);
    let props_gotonodes: boolean = true;
    if (gnd === 'false') {
      props_gotonodes = false;
    }
    let snd = accessCookie(SemTalkPortalCookie.props_shownodes);
    let props_shownodes: boolean = true;
    if (snd === 'false') {
      props_shownodes = false;
    }
    let pnd = accessCookie(SemTalkPortalCookie.props_showproperties);
    let props_showproperties: boolean = true;
    if (pnd === 'false') {
      props_showproperties = false;
    }
    this.callback = Guid.create();
    this.state = {
      // filter: this.props.filter,
      objectid: -1,
      items: [],
      hasError: false,
      // isact: false,
      // isres: false,
      object: null,
      // model: null,
      // entity: null,
      // entitytype: "",
      // rolemappings: [],
      // group: null,
      // groupMembers: [],
      // groupPlans: [],
      // grouploaded: false,
      // rolesloaded: false
      gotonodes: props_gotonodes,
      shownodes: props_shownodes,
      showproperties: props_showproperties

    };
  }
  private mounted: boolean = false;

  public async componentDidMount() {

    // await this.metamodelmapping();

    addCallBack(this);
    this.mounted = true;
    let oid: number = getObject();
    if (oid > 0) {
      await this.loadObject(oid);
    }
  }
  private loadDiag = async (_diagid: number) => {
    // let diag = await FindDiagramByID(diagid);
    // if (diag !== null) {
    //   // this.setState({ model: diag.ObjectModel });
    // }
    this.setState({
      objectid: -1,
      items: [],
      hasError: false,
      object: null,
    });
  }
  // private async loadGroup(): Promise<[DirectoryObject[], PlannerPlan[]]> {
  //   let grpMembers: DirectoryObject[] = [];
  //   let grpPlans: PlannerPlan[] = [];
  //   try{
  //     if (this.props.context) {
  //       let teamname = this.props.context.pageContext.web.title;

  //       let grp: Group | null = null;
  //       // let tea: Team | null = null;
  //       // let teaMembers: ConversationMember[]=[];

  //       if (this.props.graphClient !== undefined) {
  //         const g = "groups";
  //         const f = "displayName eq '" + teamname + "'";
  //         let tg = await fetchGraph(g, f, "", this.props.graphClient);
  //         if (tg && tg.value && tg.value.length > 0) {
  //           grp = tg.value[0] as Group;
  //           let s = "groups/" + grp.id + "/members";
  //           let tl = await fetchGraph(s, "", "", this.props.graphClient);
  //           grpMembers = tl.value;
  //           s = "groups/" + grp.id + "/planner/plans";
  //           let pl = await fetchGraph(s, "", "", this.props.graphClient);
  //           grpPlans = pl.value;
  //           this.setState({
  //             group: grp,
  //             groupMembers: grpMembers,
  //             groupPlans: grpPlans,
  //             grouploaded: true
  //           });
  //         }
  //       }
  //     }
  //   }
  //   catch(e)
  //   { 
  //     console.log("Portal cannot connect to Microsoft Graph");
  //   }
  //   return [grpMembers, grpPlans];

  // }
  // private async loadRoles(): Promise<SPListItem[]> {
  //   let res: SPListItem[] = [];
  //   if (this.props.listTitle) {
  //     let l: any = await this.getListData(this.props.listTitle, this.props.query);
  //     res = l.value;
  //     this.setState({
  //       rolemappings: res,
  //       rolesloaded: true
  //     });
  //   }
  //   return res;
  // }
  public componentWillUnmount() {
    removeCallBack(this);
    this.mounted = false;
  }
  private loadObject = async (id: number) => {
    let nodetable = await NodeNames(id);
    let proptable = await Links(id);
    let obj = await FindObjectByID(id);
    let items = this.getItems(nodetable, proptable);
    // thisdiag = getDiagram();

    // let model = getModel();
    // if (obj) model = obj.ObjectModel;

    this.setState({
      objectid: id,
      object: obj,
      // entitytype: "",
      // model: model,
      // entity: null,
      items: items
    });
  }

  public handleEvent = async (m: any): Promise<void> => {
    let mstr = JSON.stringify(m);
    await this.eventListener({ data: mstr });
  }

  private eventListener = async (e: any) => {
    if (!this.mounted) {
      return;
    }
    let md: any;
    let mtype: string = "";
    try {
      md = JSON.parse(e.data);
      mtype = md.type;
    } catch (e) {
      return;
    }


    switch (mtype) {
      case "gotoObject":
        if (md.objectid !== this.state.objectid
          //  || this.state.entity !== null
        ) {
          await this.loadObject(md.objectid);
          // this.forceUpdate();
        }
        break;
      case "gotoNode":
        if (this.state.gotonodes) {
          if (md.objectid !== this.state.objectid) {
            await this.loadObject(md.objectid);
          }
          //  this.forceUpdate();
        }
        break;
      case "gotoDocument":
        await this.loadDiag(md.diagid);
        break;
      case "gotoEntity":
        // if (md.entity != null && this.props.usegraph) {
        //   if ((this.state.entity === null) || (this.state.entity != null &&
        //     md.entity.id != this.state.entity.id)) {
        //     this.loadEntity(md.entity);
        //   }
        // }
        break;
    }
  }
  private isExtern = (_id: number): boolean => {
    return false;
  }
  private badlist: string[] = "extensionElements, Class, Klasse, Attachment, Comment, Kommentar,DocumentInfo, FlowChartElement, ChoreographyElement, Legend Shape, SAP, Offtime, Funktionsband, Functional band, Swimlane".split(", ");
  private goodlist: string[];
  private getItems(nodetable: INodeTable[], proptable: IPropertyTable[]): any[] {
    let items: any[] = [];
    let ext = getModel();
    if (this.state.shownodes) {
      let nt: INodeTable[] = nodetable;
      if (ext > -1) {
        nt = nt.filter((n: INodeTable) => {
          return !this.isExtern(n.NodeModel);
        });
      }
      for (let item of nt) {
        let gl: string[] | undefined = this.goodlist;
        let bl: string[] | undefined = this.badlist;
        if (gl !== undefined && gl.length > 0) {
          if (gl.indexOf("Diagram") > -1) {
            items.push(item);
          }
        } else {
          if (bl !== undefined && bl.length > 0) {
            if (bl.indexOf("Diagram") < 0) {
              items.push(item);
            }
          } else {
            items.push(item);
          }
        }
      }
    }
    if (items.length === 1) {
      let nd = items[0];
      if (nd.NodePage === getDiagram() && nd.NodeShape === getShape()) {
      } else {
        this._clickNode(nd);
      }
    }
    if (this.state.showproperties) {
      let gl: string[] | undefined = this.goodlist;
      if (gl) gl = [];
      let bl: string[] = [];
      if (this.badlist !== undefined) bl = this.badlist;

      let bpmnl: string[] | undefined = this.props.hidebpmn;
      let siml: string[] | undefined = this.props.hidesimulation;
      if (bpmnl) {
        bl.push(...bpmnl);
      }
      if (siml) {
        bl.push(...siml);
      }
      bl.push("Comment");
      bl.push("Kommentar");

      let pt: IPropertyTable[] = proptable;
      if (ext > -1) {
        pt = pt.filter((n: IPropertyTable) => {
          return (n.PropValueModel === undefined ||
            (n.PropValueModel !== null && !this.isExtern(n.PropValueModel)));
        });
      }
      for (let item of pt) {
        if (gl && gl.length > 0) {
          if (gl.indexOf(item.PropName) > -1) {
            items.push(item);
          }
        } else {
          if (bl && bl.length > 0) {
            if (bl.indexOf(item.PropName) < 0) {
              items.push(item);
            }
          } else {
            items.push(item);
          }
        }
      }
    }
    items = items.map((item: any) => {
      item._value = this.valueCaption(item);
      item._label = this.labelCaption(item).replace("inv ", "");
      return item;
    });
    return items;
  }
  public componentDidCatch(_error: any, _info: any) {
    this.setState({ hasError: true });
  }
  // public getSnapshotBeforeUpdate(prevProps: any, prevState: any) {

  // }
  private _columns: IColumn[] = [
    {
      key: 'Label',
      name: "this.props.strings.Label",
      fieldName: 'Label',
      minWidth: 50,
      // maxWidth: 50,
      isResizable: true,
      isMultiline: true,
      onRender: item => (
        <div>{item._label}</div>
      )
    },
    {
      key: 'Value',
      name: "this.props.strings.Value",
      fieldName: 'Value',
      minWidth: 100,
      // maxWidth: 180,
      isResizable: true,
      isMultiline: true,
      onRender: (item?: any, _index?: number, _column?: IColumn) => {
        // console.debug("OnRender");
        let cap: string;
        if (item["entity"] !== undefined) {
          return "";
          // cap = item.PropValueCaption;
          // return <Link key={item.id} onClick={() => {
          //   this._clickentity(item["entity"]);
          // }}>{cap}</Link>;
        } else {
          if (item.NodePage || item.PropValueName > 0) {
            let cap2 = item._value;
            return <Link key={item.id} onClick={async () => {
              await this._clickany(item);
            }}>
              {cap2}
            </Link>;
          }
          try {
            cap = String(item._value);
          } catch (e) {
            cap = "";
          }
          if (cap.indexOf("://") > 0) {
            return <Link key={item.id} onClick={() => {
              this._clickurl(item.PropValue);
            }}>{cap}</Link>;
          } else {
            return <span>{cap}</span>;
          }
        }
      }
    }
  ];
  public render(): React.ReactElement<ISemTalkPropertyTableProps> {

    // let val: any[] = this.getItems();
    let val: any[] = this.state.items;
    let r: React.ReactElement<ISemTalkPropertyTableProps>;
    const stackTokens = { childrenGap: 10, padding: 10 };
    // const labelStyles: Partial<IStyleSet<ITextStyles>> = {
    //   root: { marginTop: 10, marginLeft: 10 },
    // };
    r = <Fabric>
      <Stack tokens={stackTokens}>
        <StackItem>
          {this.state.object &&
            <Label>{this.state.object.ObjectCaption}</Label>
          }
        </StackItem>
        {this.state.object &&
          <StackItem>
            <Text>{this.state.object.Comment}</Text>
          </StackItem>
        }
        {/* {this.props.shownav !== undefined && this.props.shownav &&
          <Stack horizontal>
            <DefaultButton onClick={this._clickBackward}><Icon iconName="Back" /></DefaultButton>
            <DefaultButton onClick={this._clickForward}><Icon iconName="Forward" /></DefaultButton>
          </Stack>
        } */}
      </Stack>
      <DetailsList
        items={val}
        compact={true}
        columns={this._columns}
        selectionMode={SelectionMode.single}
        layoutMode={DetailsListLayoutMode.justified}
        selection={this._selection}
        checkboxVisibility={CheckboxVisibility.hidden}
        isHeaderVisible={false}
      />
    </Fabric>;
    // console.debug("r: ", r.toString(), r);
    return r;
  }

  private labelCaption(prp: any): string {
    if (prp.NodePage !== undefined) {
      return this.props.res.getResStrListener("STRDLHTMLOUTDIA");
    } else {
      return prp.PropName;
    }
  }
  private valueCaption(prp: any): string {
    if (prp.NodePage !== undefined) {
      if (prp.DiagramCaption) return prp.DiagramCaption;
      if (prp.DiagramName) return prp.DiagramName;
    } else {
      let val: any = prp.PropValue;
      let cap = prp.PropValueCaption;
      if (val === null) {
        val = "";
      }
      if (prp.PropValueName !== null && prp.PropValueName !== "0" && prp.PropValueName !== 0) {
        val = prp.PropValueName;
      }
      if (cap === "") {
        cap = val;
      }
      if (cap === null) {
        cap = val;
      }
      if (cap === null && prp.PropValue) {
        cap = prp.PropValue;
      }
      if (cap && cap.length > 0 && cap.indexOf("/") > 0 && cap.indexOf("http") === 0) {
        cap = cap.substr(cap.lastIndexOf("/") + 1);
      }
      return cap;
    }
    return "";
  }
  // private async _clickForward(_p: any) {
  //   await NavigateForward();
  // }
  // private async _clickBackward(_p: any) {
  //   await NavigateBackward();
  // }

  private _clickValue = async (prp: IPropertyTable) => {
    // let id: number = parseInt(e.target.nodeValue);
    let val: any = prp.PropValue;
    let typ = prp.PropType;
    let nam = prp.PropName;
    if (val === null) {
      val = "";
    }
    if (prp.PropValueName !== null && prp.PropValueName !== "0") {
      val = prp.PropValueName;
      if (typeof val !== "number") val = parseInt(val);
    }
    let trg = "";
    if (typ === "Reference" || typ === "External" || typ === "Page" || typ === "Attachment") {
      if (nam === "Hyperlink" || nam === "Extern") {
        trg = "_blank";
        window.open(prp.PropValue.toString(), trg);
      }
      if (typ === "Page") {
        if (nam === "Refinement" || nam === "Verfeinerung") {
          if (this.navigateproperties) await gotoDocument(val);
        } else {
          if (this.navigateproperties) await gotoDocument(val);
          // newLink.setAttribute("href", "javascript:gotoPropertyDiagram('" + val + "','" + _focus + "','" + _results + "','" + global._service + "','" + md + "')");
        }
      } else {
        if (nam === "Go Up") {
          if (this.navigateproperties) await gotoDocument(val);
        } else {
          if (prp.PropValueName !== null && prp.PropValueName !== "0") {
            if (this.navigateproperties && nam !== "Hyperlink") {
              await gotoObject(val);
              // _navigate(val);
            }
          }
        }
      }
    }
  }
  private _clickNode = async (nd: INodeTable) => {
    if (this.navigateproperties) await gotoNode(nd.ID, nd.Object, nd.NodePage, nd.NodeShape);
  }
  private _clickany = async (p: any) => {
    if (p.PropType && p.PropType === "Attachment") {
      await this._clickValue(p);
      return;
    }
    if (p.PropName === "WebUrl") {
      this._clickurl(p.PropValueCaption);
      return;
    }
    if (p.NodePage !== undefined) {
      await this._clickNode(p);
    } else {
      await this._clickValue(p);
    }
  }
  private _clickurl(p: string): any {
    try {
      window.open(p, "_blank");
    } catch (e) {
      console.debug(e);
    }
  }
}
