import { SemTalkLanguage, SemTalkLanguageCode } from './Interface';
export function Language2Code(c: SemTalkLanguage): SemTalkLanguageCode | undefined {
  switch (c) {
    case SemTalkLanguage.English: {
      return SemTalkLanguageCode.English;
    }
    case SemTalkLanguage.German: {
      return SemTalkLanguageCode.German;
    }
    case SemTalkLanguage.Japanese: {
      return SemTalkLanguageCode.Japanese;
    }
    case SemTalkLanguage.Spanish: {
      return SemTalkLanguageCode.Spanish;
    }
    case SemTalkLanguage.Russian: {
      return SemTalkLanguageCode.Russian;
    }
    case SemTalkLanguage.Simplified: {
      return SemTalkLanguageCode.Simplified;
    }
    case SemTalkLanguage.Traditional: {
      return SemTalkLanguageCode.Traditional;
    }
  }
  return SemTalkLanguageCode.English;
}
export function Code2Language(c: SemTalkLanguageCode): SemTalkLanguage {
  switch (c) {
    case SemTalkLanguageCode.English: {
      return SemTalkLanguage.English;
    }
    case SemTalkLanguageCode.German: {
      return SemTalkLanguage.German;
    }
    case SemTalkLanguageCode.Japanese: {
      return SemTalkLanguage.Japanese;
    }
    case SemTalkLanguageCode.Spanish: {
      return SemTalkLanguage.Spanish;
    }
    case SemTalkLanguageCode.Russian: {
      return SemTalkLanguage.Russian;
    }
    case SemTalkLanguageCode.French: {
      return SemTalkLanguage.French;
    }
    case SemTalkLanguageCode.Simplified: {
      return SemTalkLanguage.Simplified;
    }
    case SemTalkLanguageCode.Traditional: {
      return SemTalkLanguage.Traditional;
    }
  }
}
export function GetLanguage(c: string): SemTalkLanguage | undefined {
  switch (c) {
    case "English": {
      return SemTalkLanguage.English;
    }
    case "German": {
      return SemTalkLanguage.German;
    }
    case "Japanese": {
      return SemTalkLanguage.Japanese;
    }
    case "Spanish": {
      return SemTalkLanguage.Spanish;
    }
    case "Russian": {
      return SemTalkLanguage.Russian;
    }
    case "French": {
      return SemTalkLanguage.French;
    }
    case "Chinese (Simplified)": {
      return SemTalkLanguage.Simplified;
    }
    case "Chinese (Traditional)": {
      return SemTalkLanguage.Traditional;
    }
  }
  return undefined;
}
export function GetLanguageCode(c: string): SemTalkLanguageCode | undefined {
  switch (c) {
    case "en": {
      return SemTalkLanguageCode.English;
    }
    case "de": {
      return SemTalkLanguageCode.German;
    }
    case "ja": {
      return SemTalkLanguageCode.Japanese;
    }
    case "es": {
      return SemTalkLanguageCode.Spanish;
    }
    case "ru": {
      return SemTalkLanguageCode.Russian;
    }
    case "fr": {
      return SemTalkLanguageCode.French;
    }
    case "cn-simplified": {
      return SemTalkLanguageCode.Simplified;
    }
    case "cn-traditional": {
      return SemTalkLanguageCode.Traditional;
    }
  }
  return undefined;
}
