
import { SemTalkLanguageCode } from './tbase/Interface';
import * as resx_SemList from './SemList.json';
import * as resx_SemTalk from './SemTalk.json';
import * as helpspec from './SemTalkHelp.json';
    
export interface IResStr {
  getResStrListener(str: string, lang?: SemTalkLanguageCode): string;
  getResStr(str: string, lang?: SemTalkLanguageCode): string;
  showHelp(page?: string, lng?: string): void;
  getHelpStr(str: string, lang?: SemTalkLanguageCode): string;
}
export class ResStr implements IResStr {
  // private resx_SemList: any;
  // private resx_SemTalk: any;
  private guilanguage: SemTalkLanguageCode;

  constructor(guilanguage: SemTalkLanguageCode) {
    this.guilanguage = guilanguage;
    // this.resx_SemList = require("./SemList.json");
    // this.resx_SemTalk = require("./SemList.json");
  }
  public getResStrListener(str: string, lang?: SemTalkLanguageCode): string {
    if (lang === undefined) {
      lang = this.guilanguage;
    }
    let res: any = resx_SemList;
    if (lang === SemTalkLanguageCode.English) {
      const s = res.default[str];
      if (s !== undefined) {
        return s.value;
      }
      return str;
    } else {
      const s = res.default[str];
      if (s !== undefined) {
        let v = s[lang];
        if (v === undefined) {
          return s.value;
        }
        return v;
      } else {
        return str;
      }
    }
  }
  public getResStr(str: string, lang?: SemTalkLanguageCode): string {
    if (lang === undefined) {
      lang = this.guilanguage;
    }
    let res: any = resx_SemTalk;
    if (lang === SemTalkLanguageCode.English) {
      const s = res.default[str];
      if (s !== undefined) {
        return s.value;
      }
      return str;
    } else {
      const s = res.default[str];
      if (s !== undefined) {
        // if (lang !== "de") {
        //   if (lang === "sc") lang = "cn-simplified";
        //   if (lang === "tc") lang = "cn-traditional";
        // }
        let v = s[lang];
        if (v === undefined) {
          return s.value;
        }
        return v;
      } else {
        return str;
      }
    }
  }
  public showHelp(page?: string, lng?: string): void {
    let helpwiki: string = "https://github.com/SemTalkOnline/SemTalkOnline_EN/wiki/";
    if (!lng) {
      lng = this.guilanguage;
    }
    if (lng) {
      switch (lng) {
        case SemTalkLanguageCode.German: {
          helpwiki = "https://github.com/SemTalkOnline/SemTalkOnline_DE/wiki/";
          break;
        }
        case SemTalkLanguageCode.English: {
          helpwiki = "https://github.com/SemTalkOnline/SemTalkOnline_EN/wiki/";
          break;
        }
        default: {
          helpwiki = "https://github.com/SemTalkOnline/SemTalkOnline_EN/wiki/";
          break;
        }
      }
    }

    if (page) { window.open(helpwiki + this.getHelpStr(page), "_blank"); return; }
  }

  public getHelpStr(str: string, lang?: SemTalkLanguageCode): string {
    // let helpspec = require("./SemTalkHelp.json");
    if (lang === undefined) {
      lang = this.guilanguage;
    }
    let help: any = helpspec;
    if (lang === SemTalkLanguageCode.English) {
      const s = help.default[str];
      if (s !== undefined) {
        return s.value;
      }
      return str;
    } else {
      const s = help.default[str];
      if (s !== undefined) {
        // if (lang !== "de") {
        //   if (lang === "sc") lang = "cn-simplified";
        //   if (lang === "tc") lang = "cn-traditional";
        // }
        let v = s[lang];
        if (v === undefined) {
          return s.value;
        }
        return v;
      } else {
        return str;
      }
    }
  }
}