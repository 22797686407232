import React from 'react';
// import styles from './SemTalkCommandBar.module.scss';
import {
  CommandBar,
  // ContextualMenuItemType
} from 'office-ui-fabric-react';
// import * as strings from 'SemTalkStrings';
// import { strings_de } from '../../../strings/semTalkStrings/loc/de-de';

// import {PanelType } from 'office-ui-fabric-react/lib/Panel';
import {
  // setCookie, accessCookie, 
  // showPivot, 
  // showContext
} from '../../application/stglobal';
import { IResStr } from '../../application/resstr';
import { ISemTalkSVG, SemTalkOnlineCommand } from '../stsvg/SemTalkSvg';
export interface ISemTalkCommandBarProps {
  callback: ISemTalkSVG;
  res: IResStr;
  strings: ISemTalkStrings;
}
export interface ISemTalkCommandBarState {
  items: any[];
}
export class SemTalkCommandBar extends React.Component<ISemTalkCommandBarProps, ISemTalkCommandBarState> {
  // private showPivot: boolean = false;
  // private undoItem: any;
  // private redoItem: any;
  private forwardItem: any;
  private backwardItem: any;
  // private langItem: any;
  private leftitems: any[] = [];

  constructor(props: ISemTalkCommandBarProps) {
    super(props);
    console.debug("showCommand");

    this.state = {
      items: this.updateItems()
    };
  }
  public componentDidMount() {
    // this.items = this.updateItems();
    this.leftitems = this.getCommandBarItems(this.state.items);
  }

  public render(): React.ReactElement<ISemTalkCommandBarProps> {
    return (
      <div>
        <CommandBar
          // isSearchBoxVisible={false}
          items={this.leftitems}
          //overflowItems={this.overflowItemsNav}
          // overflowButtonProps={{ ariaLabel: 'More commands' }}
          farItems={this.faritems}
        // ariaLabel={'Use left and right arrow keys to navigate between commands'}
        />
      </div>

    );
  }

  private updateItems = (): any[] => {

    let res = this.props.callback.ParseMenu(true, this);
    let dynitems = res["allitems"];

    // if (dynitems["UndoItem"]) {
    //   this.undoItem = dynitems["UndoItem"];
    //   this.undoItem.disabled = true;
    // }
    // if (dynitems["RedoItem"]) {
    //   this.redoItem = dynitems["RedoItem"];
    //   this.redoItem.disabled = true;
    // }
    if (dynitems["goForward"]) {
      this.forwardItem = dynitems["goForward"];
      this.forwardItem.disabled = true;
    }
    if (dynitems["goBack"]) {
      this.backwardItem = dynitems["goBack"];
      this.backwardItem.disabled = true;
    }

    // if (dynitems["save"]) { dynitems["save"]["disabled"] = (!isdocument); }
    // if (dynitems["delete"]) { dynitems["delete"]["disabled"] = (!ismongo && !this.props.context); }
    // if (dynitems["checkIn"]) { dynitems["checkIn"]["disabled"] = (isreadonly && !this.props.isadmin) || !isdocument || (!this.props.ischeckedout2me && !this.props.isadmin); }
    // if (dynitems["checkOut"]) { dynitems["checkOut"]["disabled"] = (isreadonly || !isdocument || this.props.ischeckedout2me); }
    // // if (dynitems["modelProperty"]) { dynitems["modelProperty"]["disabled"] = !isdocument || !ismongo; }
    // if (dynitems["modelProperty"]) { dynitems["modelProperty"]["disabled"] = !isdocument; }
    // // if (dynitems["settings"]) { dynitems["settings"]["disabled"] = !ismongo; }

    // if (dynitems["deleteShapes"]) {
    //   dynitems["deleteShapes"]["text"] = this.props.semtalk.getResStrListener("STRDLGCMDDE") + " (" + this.props.semtalk.getResStrListener("STRDLGLSHAPES") + ")";
    // }
    // if (dynitems["procItem"]) { dynitems["procItem"]["hidden"] = !isprocess; }
    // if (dynitems["selectObject"]) { dynitems["selectObject"]["disabled"] = !isselect; }
    // if (dynitems["composeObject"]) { dynitems["composeObject"]["disabled"] = !iscomp; }
    // if (dynitems["uncomposeObject"]) { dynitems["uncomposeObject"]["disabled"] = !iscomp; }
    // if (dynitems["refineObject"]) { dynitems["refineObject"]["disabled"] = !isrefine; }
    // if (dynitems["detachObject"]) { dynitems["detachObject"]["disabled"] = !isrefine; }
    // if (dynitems["expandObject"]) { dynitems["expandObject"]["disabled"] = !isexpand; }
    // if (dynitems["numberPage"]) { dynitems["numberPage"]["disabled"] = !isprocess; }
    // if (dynitems["insertPage"]) { dynitems["insertPage"]["disabled"] = isprocess; }
    // if (dynitems["taxonomyPage"]) { dynitems["taxonomyPage"]["disabled"] = isprocess; }
    // if (dynitems["mxRadialTreeLayout"]) { dynitems["mxRadialTreeLayout"]["disabled"] = isprocess; }
    // if (dynitems["mxCircleLayout"]) { dynitems["mxCircleLayout"]["disabled"] = isprocess; }
    // if (dynitems["FormPreview"]) { dynitems["FormPreview"]["disabled"] = this.props.semtalk.template !== "cit"; }
    // // if (dynitems["Repository"]) { dynitems["Repository"]["disabled"] = !ismongo; }
    // if (dynitems["uploadOnto"]) { dynitems["uploadOnto"]["disabled"] = isprocess; }
    // if (dynitems["semantics"]) { dynitems["semantics"]["disabled"] = isprocess; }
    // // if (dynitems["customizeObject"]) { dynitems["customizeObject"]["disabled"] = !this.props.isadmin; }
    // // if (dynitems["editClass"]) { dynitems["editClass"]["disabled"] = !this.props.isadmin && isprocess; }
    // // if (dynitems["resetMM"]) { dynitems["resetMM"]["disabled"] = !this.props.isadmin; }
    // if (dynitems["mgloginItem"]) { dynitems["mgloginItem"]["disabled"] = !ismongo; }
    // if (dynitems["mglogoutItem"]) { dynitems["mglogoutItem"]["disabled"] = !ismongo; }
    // if (dynitems["mgUserProfilItem"]) { dynitems["mgUserProfilItem"]["disabled"] = !ismongo; }
    // if (dynitems["mgUserManager"]) { dynitems["mgUserManager"]["disabled"] = !ismongo; }
    // if (dynitems["semantics"]) { dynitems["semantics"]["disabled"] = !ismongo; }

    // if (dynitems["addonclick"]) { dynitems["addonclick"]["disabled"] = !this.props.isadmin; }
    // if (dynitems["addonform"]) { dynitems["addonform"]["disabled"] = !this.props.isadmin; }

    return res["items"];
  }

  // private getCommandBarItems(items: any) {
  //   let counter = 0;
  //   // let subItems: any[] = [];
  //   function pushToItem(item: any, indName: string = "") {
  //     if (indName !== "") { item.name = indName; }
  //     items.push(item);
  //     // if (counter < 15) { items.push(item); }
  //     // else { subItems.push(item); }
  //   }
  //   let div1 = false;
  //   let divider1 = { key: 'divider_1', itemType: ContextualMenuItemType.Divider };
  //   // let div2 = false;
  //   // let divider2 = { key: 'divider_2', itemType: ContextualMenuItemType.Divider };
  //   // let div3 = false;
  //   // let divider3 = { key: 'divider_3', itemType: ContextualMenuItemType.Divider };

  //   let c: string;
  //   let showprops: boolean = true;
  //   c = accessCookie(SemTalkPortalCookie.showprops);
  //   if (c === 'false') { showprops = false; }

  //   let showsearch: boolean = true;
  //   c = accessCookie(SemTalkPortalCookie.showsearch);
  //   if (c === 'false') { showsearch = false; }

  //   let shownav: boolean = true;
  //   c = accessCookie(SemTalkPortalCookie.shownav);
  //   if (c === 'false') { shownav = false; }

  //   let showdia: boolean = true;
  //   c = accessCookie(SemTalkPortalCookie.showdia);
  //   if (c === 'false') { showdia = false; }

  //   let showhie: boolean = true;
  //   c = accessCookie(SemTalkPortalCookie.showhie);
  //   if (c === 'false') { showhie = false; }

  //   let showpro: boolean = true;
  //   c = accessCookie(SemTalkPortalCookie.showpro);
  //   if (c === 'false') { showpro = false; }

  //   let showlin: boolean = false;
  //   c = accessCookie(SemTalkPortalCookie.showlin);
  //   if (c === 'true') { showlin = true; }

  //   let showcon: boolean = false;
  //   c = accessCookie(SemTalkPortalCookie.showcon);
  //   if (c === 'true') { showcon = true; }

  //   let showrep: boolean = false;
  //   c = accessCookie(SemTalkPortalCookie.showrep);
  //   if (c === 'true') { showrep = true; }

  //   let showdocinfo: boolean = false;
  //   c = accessCookie(SemTalkPortalCookie.showdocinfo);
  //   if (c === 'true') { showdocinfo = true; }


  //   pushToItem(this.pivotItem);
  //   if (shownav) { pushToItem(this.navItem, this.props.tit_navigation); }
  //   if (showsearch) { pushToItem(this.searchItem, this.props.tit_search); counter++; }
  //   if (showprops) { pushToItem(this.propsItem, this.props.tit_property); counter++; }
  //   if (showdia) { pushToItem(this.diagItem, this.props.tit_diagram); counter++; }
  //   if (showhie) { pushToItem(this.hieItem); counter++; }
  //   // if (this.props.reltreeVisible !== false) { pushToItem(this.relTreeItem); counter++; }
  //   // if (this.props.teaVisible !== false) { pushToItem(this.teaItem); counter++; }
  //   // if (this.props.docVisible !== false) { pushToItem(this.docItem); counter++; }
  //   if (showpro) { pushToItem(this.procItem); counter++; }
  //   // if (this.props.lisVisible !== false) { pushToItem(this.lisItem); counter++; }
  //   // if (this.props.wikiVisible !== false) { pushToItem(this.wikiItem); counter++; }
  //   // if (this.props.detVisible !== false) { if (!div1) { subItems.push(divider); div1 = true; } pushToItem(this.detItem, this.props.tit_details); counter++; }
  //   if (showlin) {
  //     if (!div1) { subItems.push(divider1); div1 = true; }
  //     pushToItem(this.linItem, this.props.tit_share); counter++;
  //   }
  //   if (showcon) {
  //     if (!div1) { subItems.push(divider1); div1 = true; }
  //     pushToItem(this.conItem, this.props.tit_context); counter++;
  //   }
  //   // if (this.props.propGVisible !== false) { if (!div1) { subItems.push(divider); div1 = true; } pushToItem(this.propGItem, this.props.tit_propWithGroup); counter++; }
  //   // if (this.props.useVisible !== false) { if (!div2) { subItems.push(divider); subItems.push(divider); div2 = true; } pushToItem(this.useItem); counter++; }
  //   // if (this.props.trendVisible !== false) { if (!div2) { subItems.push(divider); subItems.push(divider); div2 = true; } pushToItem(this.trendItem); counter++; }
  //   // if (this.props.whoVisible !== false) { if (!div2) { subItems.push(divider); subItems.push(divider); div2 = true; } pushToItem(this.whoItem); counter++; }
  //   if (showrep) {
  //     if (!div1) { subItems.push(divider1); div1 = true; }
  //     pushToItem(this.repItem); counter++;
  //   }
  //   // if (this.props.roleVisible !== false) { if (!div3) { subItems.push(divider); div3 = true; } pushToItem(this.roleItem); counter++; }
  //   if (showdocinfo) {
  //     if (!div1) { subItems.push(divider1); div1 = true; }
  //     pushToItem(this.docInfoItem, this.props.tit_visiodata); counter++;
  //   }
  //   // if (this.props.botVisible !== false) { if (!div3) { subItems.push(divider); div3 = true; } pushToItem(this.botItem); counter++; }

  //   // let subItem = {
  //   //   key: 'moreItem',
  //   //   name: '',
  //   //   iconProps: {
  //   //     iconName: 'More'
  //   //   },
  //   //   arialabel: '',
  //   //   subMenuProps: {
  //   //     items: subItems
  //   //   }
  //   // };
  //   // if (counter > 15) items.push(subItem);
  //   return items;

  // }
  private getCommandBarItems = (items: any): any[] => {
    // if (this.undoItem) this.undoItem["disabled"] = !this.props.semtalk.IsUndo();
    // if (this.redoItem) this.redoItem["disabled"] = !this.props.semtalk.IsRedo();
    // if (this.forwardItem) this.forwardItem["disabled"] = !this.props.callback.isForward();
    // if (this.backwardItem) this.backwardItem["disabled"] = !this.props.callback.isBackward();

    // let counter = 0;
    let leftItems: any[] = [];
    // let subMenuItems: any[] = [];
    function pushToItem(item: any) {
      // if (counter <= 8) {
      leftItems.push(item);
      // } else { subMenuItems.push(item); }
    }
    for (let item of items) {
      // {
      // if (item<["hidden"]) {
      //   continue;
      // }
      pushToItem(item);
      // counter++;
      // }
    }
    return leftItems;
  }





  private faritems = [
    {
      "key": "settings1",
      "name": this.props.res.getResStr("STROPTIONS"),
      "textL": "",
      "iconOnly": true,
      "iconProps": {
        "iconName": "Settings"
      },
      onClick: () => {
        this.props.callback.DoCommand(SemTalkOnlineCommand.ShowOptions, {});
      }
    },
    {
      key: 'info',
      name: this.props.res.getResStr("STRHELP").replace("&", ""),
      cacheKey: 'myCacheKeyInfo', // changing this key will invalidate this items cache
      iconProps: {
        iconName: 'Help'
      },
      iconOnly: true,
      keyEvent: "F1",
      onClick: () => {
        this.props.callback.showHelp("SemTalk-Overview");
        // this.props.callback.DoCommand(SemTalkOnlineCommand.ShowHelp, {});
      }
    }
  ];
  /*   private _onShowSearchPanel = (): void => {
      this.setState({ showSearch: true });
    } */

}
