/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
// import logo from './logo.svg';
import {
  createTheme,
  Customizations
} from 'office-ui-fabric-react';
import { SemTalkCookie } from './components/ISemTalkCookie';
import './App.css';
import { SemTalkSvg } from './components/controls/stsvg/SemTalkSvg';
import { IMongoOption } from './components/SemTalkMongoServer';
import { SemTalkPortalCookie } from './components/ISemTalkPortalCookie';
import { accessCookie, setCookie } from './components/application/stglobal';

// testfoo2

let ff = accessCookie(SemTalkCookie.guifont);
let theme: any;
// if (ff === 'undefined') ff = defaultFont;
if (!ff) ff = "Helvetica, Arial, sans-serif";

let th = accessCookie(SemTalkCookie.themeStyle);
let ic = accessCookie(SemTalkCookie.iconcolor);
let icj: any = undefined;
if (ic !== 'undefined' && ic !== null && ic !== "") {
  switch (ic) {
    case "Black": {
      icj = { "themePrimary": "#000000", "themeDarkAlt": '#616161', "themeDark": '#616161', "themeDarker": '#8c8c8c' };
      break;
    }
    case "Green": {
      icj = { "themePrimary": "#00c21d", "themeDarkAlt": '#009316', "themeDark": '#009316', "themeDarker": '#006d10' };
      break;
    }
    case "Red": {
      icj = { "themePrimary": "#e00004", "themeDarkAlt": '#ab0003', "themeDark": '#ab0003', "themeDarker": '#7e0002' };
      break;
    }
    case "Blue": {
      icj = { "themePrimary": "#0078d4", "themeDarkAlt": '#005ba1', "themeDark": '#005ba1', "themeDarker": '#004377' };
      break;
    }
    case "Orange": {
      icj = { "themePrimary": "#f09c00", "themeDarkAlt": '#b67600', "themeDark": '#b67600', "themeDarker": '#865700' };
      break;
    }
    case "White": {
      icj = { "themePrimary": "#ffffff", "themeDarkAlt": '#f4f4f4', "themeDark": '#f4f4f4', "themeDarker": '#f8f8f8' };
      break;
    }
    default: {
      break;
    }
  }
}
if (th !== undefined && th !== null && th !== "") {
  let themeTemplate = JSON.parse(th);
  if (themeTemplate.themePrimary !== undefined && themeTemplate.themePrimary !== null) {
    if (icj !== undefined) {
      themeTemplate.themePrimary = icj["themePrimary"];
      themeTemplate.themeDark = icj["themeDark"];
      themeTemplate.themeDarker = icj["themeDarker"];
      themeTemplate.themeDarkAlt = icj["themeDarkAlt"];
    }
  }
  if (themeTemplate.white !== undefined && themeTemplate.white !== null) {
    let bg = themeTemplate.white;
    if (bg !== 'undefined' && bg !== null && bg !== "") {
      setCookie(SemTalkCookie.backgroundColor, bg);
    }
  }
  if (themeTemplate !== 'undefined' && themeTemplate !== null) {
    theme = createTheme({
      defaultFontStyle: { fontFamily: ff },
      palette: themeTemplate
    });
  } else {
    if (icj !== undefined) {
      theme = createTheme({
        defaultFontStyle: { fontFamily: ff },
        palette: {
          themePrimary: icj["themePrimary"],
          themeDark: icj["themeDark"],
          themeDarker: icj["themeDarker"],
          themeDarkAlt: icj["themeDarkAlt"],
        }
      });
    } else {
      theme = createTheme({
        defaultFontStyle: { fontFamily: ff }
      });
    }
  }
}
else {
  if (icj !== undefined) {
    theme = createTheme({
      defaultFontStyle: { fontFamily: ff },
      palette: {
        themePrimary: icj["themePrimary"],
        themeDark: icj["themeDark"],
        themeDarker: icj["themeDarker"],
        themeDarkAlt: icj["themeDarkAlt"],
      }
    });
  } else {
    theme = createTheme({
      defaultFontStyle: { fontFamily: ff }
    });
  }
}
// if (ff !== ff0) {
Customizations.applySettings({ theme });
// }

let useapi: boolean = false;
let c;
c = accessCookie(SemTalkPortalCookie.useapi);
if (c === 'true') { useapi = true; }

let shownavigator: boolean = false;
c = accessCookie(SemTalkPortalCookie.shownavigator);
if (c === 'true') { shownavigator = true; }

let pivotvisible: boolean = false;
c = accessCookie(SemTalkPortalCookie.pivotvisible);
if (c === 'true') { pivotvisible = true; }

let svgprop: string = "never";
c = accessCookie(SemTalkPortalCookie.svgprop);
if (c !== undefined && c !== "" && c !== null) { svgprop = c; }

let svghyperlink: string = "ctrl";
c = accessCookie(SemTalkPortalCookie.svghyperlink);
if (c !== undefined && c !== "" && c !== null) { svghyperlink = c; }

let svgrefine: string = "always";
c = accessCookie(SemTalkPortalCookie.svgrefine);
if (c !== undefined && c !== "" && c !== null) { svgrefine = c; }

let usemongo: boolean = true;
let usemongos = accessCookie(SemTalkCookie.usemongo);
if (usemongos !== undefined) {
  usemongo = (usemongos !== "false");
}

let semmongoserverurl = accessCookie(SemTalkCookie.semmongoserverurl);
if (!semmongoserverurl) {
  semmongoserverurl = "https://semmongo.azurewebsites.net/api/";
  setCookie(SemTalkCookie.semmongoserverurl, semmongoserverurl);
}

// semmongoserverurl = "https://semmongo.azurewebsites.net/api/";

// semmongoserverurl = "http://localhost:7071/api/";
// semmongoserverurl = "/api/";

let semconnecttoken = accessCookie(SemTalkCookie.semconnecttoken);
if (!semconnecttoken) semconnecttoken = "";

// "mongodb://localhost:27017"
// semconnecttoken = "aef864f1cb11a4d5b9cd6421b56c113c7f7c9f17efeee15e1c03d3c67d55d6a832eb757e6e7a53cb9b1d54c74e73d291102e64b62818fb4cfd3feb6c6e76b12c54ef";

//  "mongodb://ssss:xxxx%21@semtalkmongodb.westeurope.azurecontainer.io:27017"
// semconnecttoken = "aef864f1cb11a4d5b9cd6421b56c113c7f7c9f17efeefe541216deda7b49cca853b9281b383414dada4e11e65f77cc8053782da8221be842f536aa75676cab2c03e05d3fc9e4b1798a869e7519643451e9687436cf07c154167985d81fb38a";

// "mongodb+srv://ssss:xxxx!@cluster0.pumvg.mongodb.net/semtalkonline"
// semconnecttoken = "aef864f1cb11a4d5b9cd6421b56c113c7f7c9f06b3b3fb0b504dcccb7f52c3e669b32b750a3d12bd9e104b950d52c298476732a03f45a15de439f2652c72b02711fd562d82a4b577d0879e7b026b2c5bef687d2d8f0b8c13"
// semconnecttoken = "";

let db = accessCookie(SemTalkCookie.dbname);
if (!db) db = "semtalkonline";
let re = accessCookie(SemTalkCookie.repository);
if (!re) re = "repository";


let mongo: IMongoOption = {
  usemongo: usemongo,
  semmongoserverurl: semmongoserverurl,
  documents: "SDX",
  templates: "Templates",
  stencils: "Stencils",
  semuserlogin: null,
  semmongoconnectiontoken: semconnecttoken,
  dbname: db,
  repository: re,
  iselectron: false,
  defaultdatabase: "semtalkonline",
  defaultrepository: "repository",
  objects: "Objects",
  roles: "Roles",
  users: "Users"
};
let tid = accessCookie(SemTalkCookie.teamid);
// let teamid = "1e504f68-4a10-42e2-aa15-d0861940a356";
let teamid = "";
if (tid) {
  teamid = tid;
}
let cid = accessCookie(SemTalkCookie.channelid);
// let channelid = "f916515a1687474b9120b16ceb8070eb";
let channelid = "";
if (cid) {
  channelid = cid;
}
let pid = accessCookie(SemTalkCookie.planid);
// let planid = "fVbWEtpl5Eir25_6LQIWTpYAFQm5";
let planid = "";
if (pid) {
  planid = pid;
}
let width = (window as any).innerWidth;
let w = accessCookie(SemTalkCookie.width);
if (w) width = Math.max(Number(w), 300);

let height = (window as any).innerHeight;
let he = accessCookie(SemTalkCookie.height);
if (he) height = Math.max(Number(he), 300);

let sm = accessCookie(SemTalkCookie.showmenubar);
let showmenubar: boolean = true;
if (sm === 'false') {
  showmenubar = false;
}
let fb = accessCookie(SemTalkCookie.showtoolbar);
let showtoolbar: boolean = false;
if (fb === 'true') {
  showtoolbar = true;
}
let sb = accessCookie(SemTalkCookie.showbreadcrumbs);
let showbreadcrumbs: boolean = true;
if (sb === 'false') {
  showbreadcrumbs = false;
}

// local
//  "Server=PIRATB;Initial Catalog=SemTalkPortalDB;User Id=semtalk;Password=semtalk;Persist Security Info=False;MultipleActiveResultSets=False;Encrypt=False;TrustServerCertificate=True;Connection Timeout=30;"
// let documenturl = "https://semtalk.sharepoint.com/demo/university2/VSDX/DenkmalschutzrechtlicheGenehmigung --- Denkmalschutzrechliche Prüfung.svg";
let constr = "vDxHu4Ob5/XHL6qxj5PS2/8HtQWPR4Atj15zbkw9ns4tPyUnxnX1EXF242MMmYDMY5/Rg/59BHnLnE3Cnn7o8HFnGr+BuX1kMSKk+Zd1qhSVt5iTraF6Sw6hfwuNnMLp";
let service = "https://semtalkcors4.azurewebsites.net/semservice/sembasedbjs";
// let site = "https://semtalk.sharepoint.com/demo/university2";
// let database = "XML";
let document = "DenkmalschutzrechtlicheGenehmigung.xml";
let startpage = "Denkmalschutzrechliche Prüfung";

// Visio
//  "Server=tcp:semtalkdbservereu.database.windows.net,1433;Initial Catalog=SemTalkPortalDB45;Persist Security Info=False;User ID=semtation@semtalkdbservereu;Password=SemTalk3!;MultipleActiveResultSets=False;Encrypt=True;TrustServerCertificate=False;Connection Timeout=30;"
constr = "j3Ex0PaYX9FPVebgHPYWrEkL/MB7sJBYZKYFJMBPgqbhftmY6CloPDZlHFIFQtavujVacjD2T4LYZSOqtS3ZGLK9/EBq9mZonU4NDbVH5imOMfmf/yv+c08Wr1EQVQf+MI535/u/bj4Nwa+tolucwiljju58H9EDUds7fxW+CnfUr8jbArIUkCi8UV22fXPcjQQv+lgSbCdQVUNBjwgdWZF42Pn+TE5jxBr1YGrpdblhiVibR4msU6fDY90QDlGbk9yzxXNpZrL+VpSCyM63Qsn3GTv1QVQv/u8KdSbcqfTFO1x1fqGAIfiSGIvJAQccnsyrUVouEZ3LvqGhubmiHk/8Su8KDl656ax59YAzbz8=";

// mxGraph SVG
//  "Server=tcp:semtalkdbservereu.database.windows.net,1433;Initial Catalog=SemTalkPortalDB50;Persist Security Info=False;User ID=semtation@semtalkdbservereu;Password=SemTalk3!;MultipleActiveResultSets=False;Encrypt=True;TrustServerCertificate=False;Connection Timeout=30;"
// constr = "j3Ex0PaYX9FPVebgHPYWrEkL/MB7sJBYZKYFJMBPgqbhftmY6CloPDZlHFIFQtavujVacjD2T4LYZSOqtS3ZGLK9/EBq9mZonU4NDbVH5imK3XCXXw3M+H/Q0vIQunmGJeetmKvUB/8pFlZnfQK/J56yfyB3sx0Qxl9y8mp1rjfTZcmWuPO1Pi53u2GhKzhezBY7bto7Z7IXntG9SE/V/mB/ts1kHsJLz+TBIA38wWiyLv4t2+hFgOP82BzFA+c8YQ8UayK4BLszR/mRROKo5KgkdlOlOu3hrJIY9aN8Yn8WGIYDyPYOnRprpFSUjnvEMOy7KqFzu1mi1yW6mBg+E8SJ4MkQ4VmJ9nPIPYqkAnI=";
// site = "https://semtalkonline.semtalk.com";
// database = "semtalkonline";
// document = "DenkmalschutzrechtlicheGenehmigung.xml";
// startpage = "Denkmalschutzrechtliche Prüfung";
// startpage = "Page-1";

let sst = accessCookie(SemTalkCookie.servicesservertype);
let servicesservertype = "mssql";
if (sst) {
  servicesservertype = sst;
}
let servicesservice: string = service;
c = accessCookie(SemTalkCookie.servicesserver);
if (c !== undefined && c !== "" && c !== null) { servicesservice = c; }

let servicesconnectionstring: string = constr;
c = accessCookie(SemTalkCookie.servicesconstr);
if (c !== undefined && c !== "" && c !== null) { servicesconnectionstring = c; }

let servicesdocument: string = document;
c = accessCookie(SemTalkPortalCookie.servicesdocument);
if (c !== undefined && c !== "" && c !== null) { servicesdocument = c; }

let servicesstartpage: string = startpage;
c = accessCookie(SemTalkPortalCookie.servicesstartpage);
if (c !== undefined && c !== "" && c !== null) { servicesstartpage = c; }

let spoptionsold = {
  context: null,
  startpage: servicesstartpage,
  service: servicesservice,
  servertype: servicesservertype,
  filter: "1",
  token: servicesconnectionstring,
  // site:  site,
  // database : database,
  document: servicesdocument,
  portal: "https://semtalk.sharepoint.com/sites/Berufung/SitePages/Berufung.aspx"
};
let app = accessCookie(SemTalkCookie.applicationid);
let applicationid = "f2f7dcf0-0917-4911-b587-f0c941391758";
if (app) {
  applicationid = app;
}

let usegraph: boolean = false;
let usegraphs = accessCookie(SemTalkCookie.usegraph);
if (usegraphs !== undefined) {
  usegraph = (usegraphs !== "false");
}
let usedialogs = false;
c = accessCookie(SemTalkCookie.usedialogs);
if (c && c === 'true') {
  usedialogs = true;
}
let aut = accessCookie(SemTalkCookie.authority);
let authority = "https://login.microsoftonline.com/semtalk.onmicrosoft.com";
if (aut) {
  authority = aut;
}

let sco = accessCookie(SemTalkCookie.scopes).split(";");
let scopes = ["User.Read",
  "Sites.Read.All",
  "Group.ReadWrite.All",
  "Files.Read.All"];
if (sco) {
  scopes = sco;
}
let sharepointrepository = accessCookie(SemTalkCookie.sharepointrepository);
if (!sharepointrepository) {
  // sharepointrepository = "/sites/semtalk.sharepoint.com:/sites/Modellierung:/";
  sharepointrepository = "/sites/semtalk.sharepoint.com:/demo/university2:/";
}

let showdetails: boolean = false;
c = accessCookie(SemTalkPortalCookie.pivot_showdetails);
if (c === 'true') { showdetails = true; }

let showprops: boolean = true;
c = accessCookie(SemTalkPortalCookie.showprops);
if (c === 'false') { showprops = false; }

let showdia: boolean = true;
c = accessCookie(SemTalkPortalCookie.showdia);
if (c === 'false') { showdia = false; }

function App() {

  return (
    <div className="App">
      <SemTalkSvg
        mongo={mongo}
        semservice={spoptionsold}
        useapi={useapi}
        sharepointrepository={sharepointrepository}
        width={width}
        height={height}
        showDetails={showdetails}
        showProps={showprops}
        showContext={false}
        // showCon={false}
        // showSearchDriven={false}
        // searchquery={"https://graph.microsoft.com/beta/groups/1e504f68-4a10-42e2-aa15-d0861940a356/drive/root"}
        // searchmytasks={false}
        showDiagram={showdia}
        showBreadCrumbs={showbreadcrumbs}
        showCommandBar={showmenubar}
        showToolBar={showtoolbar}
        // proc_showtasks={true}
        // proc_showroles={true}
        // proc_showrasci={false}
        // proc_showcomments={false}
        // proc_showattachment={true}
        // proc_showdoattachment={false}
        // proc_showinput={true}
        // proc_showoutput={true}
        // showListItems={false}
        // showListDocuments={false}
        // editDocumentList={false}
        // editList={false}
        // showLinks={true}
        // showDocument={false}
        // showAttachment={true}
        // showTeams={false}
        // showTasks={false}
        // islist={false}
        gotonodes={false}
        showtype={true}
        shownodes={true}
        dividetable={false}
        showproperties={true}
        shownav={false}
        hidebpmn={"ActivityType, AdHocOrdering, adoc, adhoc, BoundaryType, BpmnScript, Collapsed, Compensation, SubProcessType, Transaction".split(", ")}
        hidesimulation={"Syncing, Waiting, Amount".split(", ")}
        // showPropsGrouped={false}
        // goodlist={[]}
        // badlist={"Class, Klasse, Attachment, Comment, DocumentInfo,FlowChartElement, ChoreographyElement, Legend Shape, SAP, Offtime, Funktionsband, Functional band, Swimlane".split(", ")}
        objroot={""}
        navroot={""}
        views={""}
        // listdocumentslibrary={"Links"}
        // listdocumentsquery={"$select=FileLeafRef,ID,Created,Author/ID,Author/Title&$expand=Author/ID,Author/Title"}
        // listitemssite={"https://semtalk.sharepoint.com/demo/university2"}
        // listdocumentssite={"https://semtalk.sharepoint.com/demo/university2"}
        // listitemslist={"Issues"}
        // listitemscolumns={"Title, Author/Title, Created, Priority, Comment".split(", ")}
        // listdocumentscolumns={"Title, Author/Title, Created".split(", ")}
        // listitemsquery={"$select=Title,ID,Created,Author/ID,Author/Title,Priority,Status,Comment&$expand=Author/ID,Author/Title"}
        // title = {"SemTalk"}
        defaulttopic={""}
        nrOfItems={10}
        teamid={teamid}
        planid={planid}
        channelid={channelid}
        // showBot={false}
        // botsecret={"kvrWTBgP2m4.sruc5QD2FJc9Kn4mdQ2z2j4KN2k-of24fD8-YZVVwg0"}
        // showWiki={false}
        // wikilist={"Wiki"}
        // wikisite={"https://semtalk.sharepoint.com/demo/university2"}
        // wikieditlist={true}
        // wikiiscombo={true}
        usegraph={usegraph}
        azureAD={{ clientID: applicationid, authority: authority, scopes: scopes }}
        isSingleDocument={false}
        // navVisible={true}
        // searchVisible={true}
        // propsVisible={true}
        // diagVisible={true}
        // hieVisible={true}
        // reltreeVisible={true}
        // teaVisible={true}
        // docVisible={true}
        // procVisible={true}
        // lisVisible={true}
        // wikiVisible={true}
        // detVisible={true}
        // linVisible={true}
        // propGVisible={true}
        // useVisible={true}
        // trendVisible={true}
        // whoVisible={true}
        // conVisible={true}
        // repVisible={true}
        // roleVisible={true}
        // docInfoVisible={true}
        // botVisible={true}
        objIsList={false}
        objIscombo={false}
        objBadlist={""}
        objGoodlist={""}
        svgprop={svgprop}
        svghyperlink={svghyperlink}
        svgrefine={svgrefine}
        paneltype={3}
        pivotminwidth={"30%"}
        relationroot={"ProcessLv1"}
        relationlist={["has subprocess"]}
        // refine_pagehyperlinks = {false}
        // refine_hyperlinkrefinements = {false}
        // open_externalhyperlinks = {false}
        // dividetable = {false}
        // tit_property={""}
        // tit_search={""}
        // tit_diagram={""}
        // tit_propWithGroup={""}
        // tit_context={""}
        // tit_details={""}
        // tit_visiodata={""}
        // tit_share={""}
        // tit_hyperlinks={""}
        // tit_navigation={""}
        shownavigator={shownavigator}
        pivotvisible={pivotvisible}
        usedialogs={usedialogs}
      />
    </div >
  );
}

export default App;
